import { flatten } from 'lodash'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { Box } from './Box'
import { GRID } from '../constants/GRID'

const getColumns = ({ cols = GRID.COLUMNS }) => `repeat(${cols}, 1fr)`

const colStyle = ({ area = 1 }) => {
  let [firstArea, finalArea] = flatten([area, area])
  return css`
    grid-column-start: ${firstArea};
    grid-column-end: ${finalArea + 1};
  `
}

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: ${getColumns};
  grid-gap: ${GRID.GUTTER};
`

export const Col = styled(Box)`
  ${colStyle}
`
