import React from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'

import { DateInput } from 'ui'

export const DateInputField = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <StyledDateInput
        name={name}
        value={field.value || ''}
        onChange={date => form.setFieldValue(name, date)}
        {...props}
      />
    )}
  </Field>
)


// Components and Styles

const StyledDateInput = styled(DateInput)`
  margin-bottom:16px;
`