import React from 'react'
import { Link, useCurrentRoute } from 'react-navi'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { get } from 'lodash'
import { Icon, COLORS } from 'ui'
import { IoIosArrowForward } from 'react-icons/io'

export const Breadcrumbs = () => {
  const route = useCurrentRoute()
  const crumbs = get(route, 'data.breadcrumbs', [])

  return (
    <React.Fragment>
      {crumbs.map(crumb => (
        <React.Fragment key={crumb.path}>
          <NavLink href={crumb.path} active={crumb.isActive}>
            {crumb.name}
          </NavLink>
          {crumb.isActive || (
            <NavArrowIcon>
              <IoIosArrowForward />
            </NavArrowIcon>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

const navLinkStyles = {
  base: css`
    font-family: 'Roboto Condensed';
    &:hover {
      color: ${COLORS.PRIMARY_TEXT};
    }
  `,
  inactive: css`
    color: ${COLORS.SECONDARY};
    text-decoration: underline;
    &:hover {
      text-decoration:underline;
    }
  `,
  active: css`
    color: ${COLORS.PRIMARY_TEXT};
  `
}

const NavLink = styled(Link)`
  ${navLinkStyles.base}
  ${props => props.active && navLinkStyles.active}
  ${props => props.active || navLinkStyles.inactive}
`
const NavArrowIcon = styled(Icon)`
  color: ${COLORS.SECONDARY};
  padding: 0 6px;
`