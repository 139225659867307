import React from 'react'
import { Link, View } from 'react-navi'

import './BaseLayout.scss'

export class BaseLayout extends React.Component {

  render() {
    return (
      <div className="base-view container">
      <div className="row nav-items">
        <div className="col col-4">
          <Link activeClassName='is-active' href='/user/sign-in'>Sign In</Link>
        </div>
        <div className="col col-6">
          <Link activeClassName='is-active' href='/user/create'>Create Account</Link>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <View />
        </div>
      </div> 
    </div>
    )
  }
};