import React from 'react';
import './UserCard.scss'
      
export class UserCard extends React.Component {
  render() {
    console.log(this.props.gridView);
    return (
      <div className={"text-center " + (this.props.gridView === 'grid' ? 'col-4': 'col-12')}>
         <div className="user-card">
             <div className="edit-user"><i className="fas fa-check align-self-center"></i> <span className="align-self-center">edit</span></div>
            
            <div className="row">              

              <div className={"text-center avatar-holder " + (this.props.gridView === 'grid' ? 'col-12': 'col-1')}>
                <i className="fas fa-user-circle align-self-center"></i>
              </div>

              <div className={"col name-email text-center " + (this.props.gridView === 'grid' ? 'col-12': 'col-5')}>
                <span className="align-self-center">
                  First Name Last Name<br /><span className="email">email.emailaddress.com</span>
                </span>
              </div>

              <div className={"col date-time text-left " + (this.props.gridView === 'grid' ? 'col-12': 'col-3')}>
                <span className="align-self-center">created 01-10-20</span>
              </div>

              <div className={"col date-time text-left " + (this.props.gridView === 'grid' ? 'col-12': 'col-3')}>
                <span className="align-self-center">modified 01-10-20</span>
              </div>

            </div>
         </div>
      </div>
    );
  }
};