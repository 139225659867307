import React from 'react'
import client from './apollo'
import { compose, withContext, withData, withView, mount, route, redirect } from 'navi'
import { BaseLayout, AdminLayout, ProjectLayout } from 'layouts'
import { CourseQuery, CurriculumQuery, LearningObjectQuery } from 'gql'
import {
  Audiences,
  CourseCreate,
  CourseList,
  CourseManage,
  CreateAccount,
  CurriculumCreate,
  CurriculumDetail,
  CurriculumList,
  CurriculumManage,
  CurriculumManageCourses,
  LandingPage,
  LearningObjectCreate,
  LearningObjectList,
  LearningObjectManage,
  ProjectHome,
  Reports,
  SignIn,
} from 'views'

// Populate breadcrumbs array in the data of each route
const withBreadcrumb = (name) => {
  const nameFn = typeof name === 'function' ? name : () => name
  return compose(
    withContext(async (req, ctx) => {
      const name = await nameFn(req, ctx)
      const breadcrumbs = [...(ctx.breadcrumbs || []), {
        name,
        path: req.mountpath,
        isActive: req.mountpath === req.originalUrl
      }]
      return { ...ctx, breadcrumbs };
    }),
    withData((req, ctx) => ({
      breadcrumbs: [...ctx.breadcrumbs]
    }))
  )
}

const withSidebar = (sidebar) => withData({ sidebar })

const routes = mount({

  '/': route({
    title: 'Learning Everywhere',
    view: <LandingPage />
  }),

  '/user': compose(
    withView(<BaseLayout />),
    mount({

      '/': redirect('/user/sign-in'),

      '/sign-in': route({
        view: <SignIn />
      }),
    
      '/create': route({
        view: <CreateAccount />
      })
    })
  ),

  '/admin': redirect('/'),
  
  '/admin/projects': redirect('/'),
  
  '/admin/projects/:projectID': compose(
    withBreadcrumb('Home'),
    withView(<AdminLayout />),
    withView(<ProjectLayout />),
    mount({

      '/': compose(
        withSidebar(<ProjectHome.Sidebar />),
        route({
          view: <ProjectHome />
        })
      ),

      '/curriculums': compose(
        withBreadcrumb('Curriculums'),
        mount({

          '/': compose(
            withSidebar(<CurriculumList.Sidebar />),
            route({
              view: <CurriculumList />
            })
          ),

          '/new': compose(
            withBreadcrumb('New'),
            withSidebar(<CurriculumCreate.Sidebar />),
            route({
              view: <CurriculumCreate />
            })
          ),

          '/:curriculumID': compose(
            withBreadcrumb(async req => {
              const { data } = await client.query({
                query: CurriculumQuery,
                variables: {
                  id: req.params.curriculumID
                }
              })
              return data.node.name
            }),
            mount({

              '/': compose(
                withSidebar(<CurriculumDetail.Sidebar />),
                route({
                  view: <CurriculumDetail />
                })
              ),

              '/manage': compose(
                withBreadcrumb('Manage'),
                mount({

                  '/': compose(
                    withSidebar(<CurriculumManage.Sidebar />),
                    route({
                      view: <CurriculumManage />
                    })    
                  ),

                  '/courses': compose(
                    withBreadcrumb('Courses'),
                    withSidebar(<CurriculumManageCourses.Sidebar />),
                    route({
                      view: <CurriculumManageCourses />
                    })
                  )
                })                
              )
            })
          )
        })
      ),

      '/courses': compose(
        withBreadcrumb('Courses'),
        mount({

          '/': compose(
            withSidebar(<CourseList.Sidebar />),
            route({
              view: <CourseList />
            })
          ),

          '/new': compose(
            withBreadcrumb('New'),
            withSidebar(<CourseCreate.Sidebar />),
            route({    
              view: <CourseCreate />
            })
          ),

          '/:courseID': compose(
            withBreadcrumb(async req => {
              const { data } = await client.query({
                query: CourseQuery,
                variables: {
                  id: req.params.courseID
                }
              })
              return data.node.name
            }),
            withSidebar(<CourseManage.Sidebar />),
            route({
              view: <CourseManage />
            })
          )
        })
      ),

      '/learning-objects': compose(
        withBreadcrumb('Learning Objects'),
        mount({

          '/': compose(
            withSidebar(<LearningObjectList.Sidebar />),
            route({
              view: <LearningObjectList />
            })
          ),

          '/new': compose(
            withBreadcrumb('New'),
            withSidebar(<LearningObjectCreate.Sidebar />),
            route({
              view: <LearningObjectCreate />
            })
          ),

          '/:learningObjectID': compose(
            withBreadcrumb(async req => {
              const { data } = await client.query({
                query: LearningObjectQuery,
                variables: {
                  id: req.params.learningObjectID
                }
              })
              return data.node.name
            }),
            withSidebar(<LearningObjectManage.Sidebar />),
            route({
              view: <LearningObjectManage />
            })
          )
        })
      ),

      '/reports': compose(
        withBreadcrumb('Reports'),
        withSidebar(<Reports.Sidebar />),
        route({
          view: <Reports />
        })
      ),

      '/audiences': compose(
        withBreadcrumb('Audiences'),
        withSidebar(<Audiences.Sidebar />),
        route({
          view: <Audiences />
        })
      )
    })
  )
})

export { routes };
