import React from 'react'
import { Link, useNavigation } from 'react-navi'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { Mutation } from 'react-apollo'

import { LearningObjectQuery, LearningObjectMutation } from 'gql'
import { LearningObjectForm } from 'views'
import { Sidebar } from 'components'
import { Button, Panel, Title } from 'ui'
import { submit, useParam, useSimpleQuery } from 'utils'
import { LearningObjectFormModel } from 'formModels'

const { validationSchema, format, parse } = LearningObjectFormModel

export const LearningObjectManage = () => {
  const id = useParam('learningObjectID')
  const [data, loading] = useSimpleQuery(LearningObjectQuery, { id })
  const navigation = useNavigation()

  return loading || (
    <Mutation mutation={LearningObjectMutation('UPDATE')}>
      {mutate => (
        <Formik
          validationSchema={validationSchema}
          initialValues={format(data.node)}
          onSubmit={async (values) => {
            try {
              await submit(parse(values), mutate)
              navigation.navigate('.')
            } catch (err) {
              alert('Whoopsie', err)
            }
          }}
        >
          {formikProps => (
            <Form>
              <Panel>

                <Panel.Header>
                  <Title>Edit {data.node.name}</Title>
                  <ControlBox>
                    <BackLink variant='discard' href='..'>Back</BackLink>
                    <Button variant='persist' type='submit'>Save</Button>
                  </ControlBox>
                </Panel.Header>

                <LearningObjectForm {...formikProps} />

              </Panel>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>
  )
}

// Components and Styles

const BackLink = Button.withComponent(Link)

const ControlBox = styled.div`
  display:flex;
  width:180px;
  justify-content:space-between;
`

// Sidebar

LearningObjectManage.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Edit your learning object. Learning objects can be assigned to courses.
    </Sidebar.Message>
  </Sidebar.Container>
)