import React, { useState, Suspense } from 'react'
import { get } from 'lodash'
import { IoIosArrowBack } from 'react-icons/io'
import { NotFoundBoundary, View, useCurrentRoute } from 'react-navi'
import styled from '@emotion/styled'

import { COLORS, PlainButton, Grid, Col } from 'ui'
import { NotFound } from 'views'

export const ProjectLayout = () => {

  const [isOpen, setOpen] = useState(true)
  const route = useCurrentRoute()

  const sidebarCpt = get(route, 'data.sidebar')

  return (
    <Container cols={3}>
      <Backdrop />
      <Aside area={1} isOpen={isOpen}>
        {route.error ? <NotFound.Sidebar error={route.error} /> : sidebarCpt}
      </Aside>
      <Main isOpen={isOpen}>
        <CloseButton onClick={() => setOpen(!isOpen)} isOpen={isOpen}>
          <IoIosArrowBack />
        </CloseButton>
        <NotFoundBoundary render={error => <NotFound error={error} />}>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </NotFoundBoundary>
      </Main>
    </Container>
  )
}

// CSS calc directive that will match our grid.
// Unfortunately grid-template-rows animation is not universally supported.
const LEFT_CALC = 'calc((100% - 8px)/3 + 8px)'

const Backdrop = styled.div`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:${COLORS.BACKGROUND_ACCENT};
`
const Container = styled(Grid)`
  position:absolute;
  top:70px;
  right:0;
  bottom:0;
  left:0;
`
const Aside = styled(Col)`
  position:relative;
  transition:opacity 100ms ease;
  opacity: ${props => props.isOpen ? '1' : '0'};
`
const Main = styled.div`
  position:absolute;
  top:0;
  right:0;
  left:${props => props.isOpen ? LEFT_CALC : '32px'};
  transition:left 200ms ease;
`
const CloseButton = styled(PlainButton)`
  position:absolute;
  top:0;
  left:-32px;
  width:32px;
  height:40px;
  text-align:center;
  font-size:24px;
  transform:rotateY(${props => props.isOpen ? '0deg' : '180deg'});
  transition:transform 200ms ease;
`
