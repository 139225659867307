import React from 'react'

import { Sidebar } from 'components'
import { Panel } from 'ui'

export const CurriculumManageCourses = () => (
  <Panel>
    <Panel.Section>
      Curriculum Courses View
    </Panel.Section>
  </Panel>
);

CurriculumManageCourses.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Choose the courses for this curriculum. You can also create new courses.
    </Sidebar.Message>  
    <Sidebar.Action href='../../../../courses'>
      Manage Courses
    </Sidebar.Action>
  </Sidebar.Container>
)