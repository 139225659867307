import React, { Suspense } from 'react'
import { Global } from '@emotion/core'
import { Router, View, NotFoundBoundary } from 'react-navi'
import { ApolloProvider } from 'react-apollo'

import client from './apollo'
import { routes } from 'routes'
import { GLOBAL_STYLES } from 'ui'
import './App.scss'

//Bridge
import { Bridge } from '@biw/bridge';
import auth from './app-auth.js';

// function PrivateRoute({ component: Component, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         fakeAuth.isAuthenticated ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/sign-in",
//               state: { from: props.location }
//             }}
//           />
//         )
//       }
//     />
//   );
// }

// const fakeAuth = {
//   isAuthenticated: true,
//   authenticate(cb) {
//     this.isAuthenticated = true;
//     setTimeout(cb, 100); // fake async
//   },
//   signout(cb) {
//     this.isAuthenticated = false;
//     setTimeout(cb, 100);
//   }
// };


const App = () => (
  <Bridge
    auth={auth}
  //onSignIn={this.getUser}
  //onPermissionsChanged={this.handlePermissionChange}
  >
    <ApolloProvider client={client}>
      <Suspense fallback={null}>
        <Router routes={routes}>
          <Global styles={GLOBAL_STYLES} />
          <div className="App">
            <NotFoundBoundary render={error => <p>{error.message}</p>}>
              <View />
            </NotFoundBoundary>
          </div>
        </Router>
      </Suspense>
    </ApolloProvider>
  </Bridge>
)

export default App