import React from 'react'
import styled from '@emotion/styled'

import { Sidebar } from 'components'
import { COLORS, FONT_SIZES, Panel } from 'ui'

export const NotFound = ({ error }) => (
  <Panel>
    <Panel.Section>
      <Container>
        <ErrorMessage>404</ErrorMessage>
        <ErrorDetails>{error.message}</ErrorDetails>
      </Container>
    </Panel.Section>
  </Panel>
)

NotFound.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Whoops! That page could not be found.
    </Sidebar.Message>
    <Sidebar.Action href='/admin/projects/sanofi'>
      Back
    </Sidebar.Action>
  </Sidebar.Container>
)

// Components and Styles

const Container = styled.div`
  padding:160px 16px;
`
const ErrorMessage = styled.h1`
  font-size:50px;
  font-weight:300;
  text-align:center;
`
const ErrorDetails = styled.p`
  margin-top:16px;
  font-size:${FONT_SIZES.SMALL};
  color:${COLORS.SECONDARY};
  text-align:center;
`
