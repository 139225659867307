import * as yup from 'yup'
import uuid from 'uuid/v4'

const createAssetModel = (asset) => ({
  originalId: asset ? asset.id : null,
  originalName: asset ? asset.name : null,
  id: asset ? asset.id : null,
  name: asset ? asset.name : null,
  asset: null
})

export const LearningObjectFormModel = {
  validationSchema: yup.object().shape({
    id: yup.string(),
    name: yup.string().required('Please enter a learning object name.'),
    description: yup.string(),
    format: yup.mixed().oneOf([
      'scorm',
      'link',
      'file',
      'video',
      'text'
    ]),
    textContent: yup.mixed().when('format', {
      is: 'text',
      then: yup.string().required('Please enter text content.'),
      otherwise: yup.string()
    }),
    linkContent: yup.mixed().when('format', {
      is: 'link',
      then: yup.string().required('Please enter a link.'),
      otherwise: yup.string()
    }),
    fileContent: yup.mixed().when('format', {
      is: 'file',
      then: yup.object().shape({
        asset: yup.object().nullable().required('Please upload a file.')
      }),
      otherwise: yup.object()
    }),
    scormContent: yup.mixed().when('format', {
      is: 'scorm',
      then: yup.object().shape({
        asset: yup.object().nullable().required('Please upload a SCORM file.')
      }),
      otherwise: yup.object()
    }),
    videoContent: yup.mixed().when('format', {
      is: 'video',
      then: yup.object().shape({
        asset: yup.object().nullable().required('Please upload a video.')
      }),
      otherwise: yup.object()
    }),
    tags: yup.array()
  }),
  initialValues: () => ({
    id: uuid(),
    format: 'scorm',
    name: '',
    description: '',
    textContent: '',
    linkContent: '',
    fileContent: createAssetModel(),
    scormContent: createAssetModel(),
    videoContent: createAssetModel(),
    tags: []
  }),
  format: (node) => ({
    id: node.id,
    format: node.format,
    name: node.name,
    description: node.description || '',
    textContent: node.textContent || '',
    linkContent: node.linkContent || '',
    fileContent: createAssetModel(node.fileContent),
    scormContent: createAssetModel(node.scormContent),
    videoContent: createAssetModel(node.videoContent),
    tags: node.tags
  }),
  parse: (form) => ({
    variables: {
      input: {
        id: form.id,
        format: form.format,
        name: form.name,
        description: form.description || null,
        textContent: form.textContent || null,
        linkContent: form.linkContent || null,
        fileContentID: form.fileContent.id,
        scormContentID: form.scormContent.id,
        videoContentID: form.videoContent.id,
        tags: form.tags,
        assets: [
          ...(form.fileContent.asset ? [form.fileContent.asset] : []),
          ...(form.scormContent.asset ? [form.scormContent.asset] : []),
          ...(form.videoContent.asset ? [form.videoContent.asset] : [])
        ],
      }
    }
  })
}