import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { PlainButton } from './PlainButton'
import { COLORS } from '../constants/COLORS'
import { FONT_SIZES } from '../constants/FONT_SIZES'

const VARIANTS = {
  persist: () => css`
    background-color: ${COLORS.PRIMARY_ACCENT};
    color: ${COLORS.PRIMARY_TEXT};
    min-width: 80px;
    &:hover {
      color: ${COLORS.PRIMARY_TEXT};
    }
  `,
  discard: () => css`
    background-color: ${COLORS.TERTIARY};
    color: ${COLORS.TERTIARY_TEXT};
    min-width: 80px;
    &:hover {
      color: ${COLORS.TERTIARY_TEXT};
    }
  `,
  action: () => css`
    padding: 10px 18px;
    background-color: ${COLORS.ACTION};
    color: ${COLORS.ACTION_TEXT};
    text-transform: uppercase;
    box-shadow: 0 4px 8px rgba(0,0,0,0.36);
    &:disabled {
      background-color: ${COLORS.TERTIARY};
      color: ${COLORS.TERTIARY_TEXT};
      box-shadow: none;
      cursor: default;
    }
  `,
  start: () => css`
    display:flex;
    align-items:center;
    justify-content:center;
    width:190px;
    height:100px;
    text-align:center;
    background-color: ${COLORS.ACTION};
    color: ${COLORS.ACTION_TEXT};
    font-size: ${FONT_SIZES.XLARGE};
    font-weight: 300;
    box-shadow: 0 2px 4px rgba(0,0,0,0.36);
    line-height: 1.3;
  `,
  icon: ({ active }) => css`
    display:flex;
    align-items:center;
    justify-content:center;
    width:90px;
    height:90px;
    padding:0;
    color:${COLORS.SECONDARY};
    font-size:44px;
    line-height:90px;
    vertical-align:middle;
    box-shadow: 0 4px 8px rgba(0,0,0,0.18);
    margin-bottom:16px;
    background-color:${active ? COLORS.PRIMARY : COLORS.BACKGROUND};
  `
}

const variantStyles = ({ variant, ...props }) => (
  variant && VARIANTS[variant](props)
)

export const Button = styled(PlainButton)`
  display:inline-block;
  font-size: ${FONT_SIZES.SMALL};
  font-weight: 400;
  padding: 14px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  text-align:center;
  ${variantStyles}
`