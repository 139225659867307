import { useEffect, useState } from 'react'

/* -------------------------------------------------------------------------- *
 * useDebounce
 *
 * Debounce a value for a specified period.
 * https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 * 
 * Example usage:
 * 
 * const MyLookupComponent = () => {
 *   const [search, setSearch] = useState('')
 *   const debouncedSearch = useDebouncedValue(search, 500)
 *   return (
 *     <div>
 *       // State is updated on every change ...
 *       <input onChange={e => setSearch(e.target.value)} />
 *       // ... but search variable passed to Apollo only changes every 500ms
 *       <Query query={SomeQuery} variables={{ search: debouncedSearch }}>
 *         {({ data }) => (
 *           <FetchedResource data={data} />
 *         )}
 *       </Query>
 *     </div>
 *   )
 * }
 * -------------------------------------------------------------------------- */
export const useDebouncedValue = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [value])

  return debouncedValue
}
