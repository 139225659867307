import React, { useRef, useState } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { MdCloudUpload } from 'react-icons/md'
import { Field } from 'formik'
import uuid from 'uuid/v4'

import { COLORS, Box, Icon, PlainButton } from 'ui'

export const UploadField = ({ name, ...userProps }) => (
  <Field name={name}>
    {formikProps => (
      <FieldHandler {...formikProps} {...userProps} /> 
    )}
  </Field>
)

const FieldHandler = ({ field, form, accept, ...props }) => {
  const { current: originalId } = useRef(field.value.originalId)
  const { current: originalName } = useRef(field.value.originalName)
  const inputRef = useRef(null)
  const [focused, setFocused] = useState(false)

  const { name, value } = field

  const handleFile = (e) => {
    const file = e.target.files[0]
    if (!file) return
    const id = uuid()
    form.setFieldTouched(name, true)
    form.setFieldValue(name, {
      id,
      name: file.name,
      asset: { id, file }
    })
  }

  const handleRevert = () => {
    form.setFieldTouched(name, true)
    form.setFieldValue(name, {
      id: originalId,
      name: originalName,
      asset: null
    })
    inputRef.current.value = null
  }

  const handleRemove = () => {
    form.setFieldTouched(name, true)
    form.setFieldValue(name, {
      id: null,
      name: null,
      asset: null
    })
    inputRef.current.value = null
  }

  const handleOpen = () => {
    inputRef.current.click()
  }

  return (
    <Box>
      <Canvas focused={focused} {...props}>
        <Border isVisible={!value.name}>
          {value.name || (
            <UploadIcon>
              <MdCloudUpload />
            </UploadIcon>
          )}
        </Border>
        <FileInput
          type='file'
          accept={accept}
          onChange={handleFile}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          ref={inputRef}
        />
      </Canvas>
      <Controls>
        {(value.id) && (
          <ControlButton type='button' onClick={handleRemove}>
            Remove
          </ControlButton>
        )}
        {!value.id && (
          <ControlButton type='button' onClick={handleOpen}>
            Select&nbsp;File
          </ControlButton>
        )}
        {value.id && (value.id === originalId) && (
          <>
            &nbsp;•&nbsp;
            <ControlButton type='button' onClick={handleOpen}>
              Change
            </ControlButton>
          </>
        )}
        {originalId && (value.id !== originalId) && (
          <>
            &nbsp;•&nbsp;
            <ControlButton type='button' onClick={handleRevert}>
              Revert&nbsp;to&nbsp;Saved
            </ControlButton>
          </>
        )}
      </Controls>
    </Box>
  )
}


// Components and Styles

const focusedStyles = ({ focused }) => focused && css`
  border:1px solid ${COLORS.SECONDARY};
`
const Canvas = styled.label`
  position:relative;
  display:block;
  padding:4px;
  background-color:${COLORS.TERTIARY};
  background-size:cover;
  background-position:center;
  cursor:pointer;
  height:64px;
  border:1px solid transparent;
  transition:border 150ms ease;
  ${focusedStyles}
`
const Border = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  height:100%;
  border:2px dashed transparent;
  padding:12px;
  z-index:0;
  cursor:pointer;
  ${props => props.isVisible && 'border-color:#fff;'}
`
const UploadIcon = styled(Icon)`
  font-size:26px;
  color:#fff;
`
const FileInput = styled.input`
  position:fixed;
  top:100%;
  display:block;
  opacity:0;
`
const ControlButton = styled(PlainButton)`
  color:${COLORS.SECONDARY};
  line-height:1.3;
  &:hover {
    text-decoration:underline;
  }
`
const Controls = styled.div`
  margin-top:8px;
  font-size:12px;
  color:${COLORS.SECONDARY};
`