import { gql } from 'apollo-boost'

export const CourseMutation = (operation) => {
  const field = operation === 'CREATE' ? 'createCourse' : 'updateCourse'
  return gql`
    mutation CourseMutation($input:CourseInput!) {
      result: ${field} (input:$input) {
        requestID
      }
    }
  `
  }
