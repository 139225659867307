import React from 'react'
import { ErrorMessage } from 'formik'
import styled from '@emotion/styled'

import { COLORS, FONT_SIZES } from 'ui'

export const FormError = ({ name, ...props }) => (
  <ErrorMessage name={name}>
    {message => (
      <ErrorBox>{message}</ErrorBox>
    )}
  </ErrorMessage>
)


// Components and Styles

const ErrorBox = styled.p`
  margin-top:8px;
  font-size: ${FONT_SIZES.SMALL};
  color: ${COLORS.DESTRUCTIVE};
`