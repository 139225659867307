import { useCurrentRoute } from 'react-navi'
import { get } from 'lodash'

/* -------------------------------------------------------------------------- *
 * useParam
 *
 * Shortcut to get a route param value from the current route. Example usage:
 * 
 * // At admin/projects/sanofi/courses/356c3fdf-fe1f-4b75-aa74-0a35d49eaf89
 * const id = useParam('courseID') // "356c3fdf-fe1f-4b75-aa74-0a35d49eaf89"
 * -------------------------------------------------------------------------- */
export const useParam = (name) => {
  const route = useCurrentRoute()
  return get(route.lastChunk.request.params, name)
}