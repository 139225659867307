import * as yup from 'yup'
import { parseInt } from 'lodash'
import uuid from 'uuid/v4'

export const CurriculumFormModel = {
  validationSchema: yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required('Please enter a curriculum name.'),
    description: yup.string(),
    isRequired: yup.boolean(),
    hasDuration: yup.boolean(),
    duration: yup.mixed().when('hasDuration', {
      is: true,
      then: yup.number().required('Please enter a curriculum duration in days.'),
      otherwise: yup.number()
    }),
    hasDeadline: yup.boolean(),
    deadline: yup.mixed().when('hasDeadline', {
      is: true,
      then: yup.mixed().required('Please enter a curriculum deadline.'),
      otherwise: yup.mixed()
    }),
    courseEdges: yup.array()
  }),
  initialValues: () => ({
    id: uuid(),
    name: '',
    description: '',
    isRequired: false,
    hasDuration: false,
    duration: '',
    hasDeadline: false,
    deadline: '',
    courseEdges: []
  }),
  format: (node) => ({
    id: node.id,
    name: node.name,
    description: node.description || '',
    isRequired: node.isRequired,
    hasDuration: !!node.duration,
    duration: node.duration ? String(node.duration) : '',
    hasDeadline: !!node.deadline,
    deadline: new Date(node.deadline * 1000),
    courseEdges: []
  }),
  parse: (form) => ({
    variables: {
      input: {
        id: form.id,
        name: form.name,
        description: form.description || null,
        isRequired: form.isRequired,
        duration: form.hasDuration && form.duration ? parseInt(form.duration) : null,
        deadline: form.hasDeadline && form.deadline ? Math.round(form.deadline.getTime() / 1000)  : null,
        courseEdges: form.courseEdges
      }
    }
  })
}