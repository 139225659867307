import React from 'react'
import { View } from 'react-navi'

import { ToolBar } from './ToolBar'

export const AdminLayout = () => (
  <React.Fragment>
    <ToolBar />
    <View />
  </React.Fragment>
)