import React from 'react'
import { MdCheckCircle } from 'react-icons/md'
import styled from '@emotion/styled'

import { COLORS } from '../constants/COLORS'
import { FONT_SIZES } from '../constants/FONT_SIZES'
import { FlexBox } from './Box'
import { Icon } from './Icon'

export const StatusLight = ({ children, done }) => (
  <Wrapper>
    <IconWrapper done={done}>
      <MdCheckCircle />
    </IconWrapper>
    {children}
  </Wrapper>
)

// Components and Styles

const Wrapper = styled(FlexBox)`
  font-weight: 300;
  color: ${COLORS.SECONDARY};
  align-items: top;
  padding-top:1px;
  line-height:1;
  margin:16px;
`
const IconWrapper = styled(Icon)`
  font-size: ${FONT_SIZES.LARGE};
  margin: -2px 6px 0 0;
  color: ${props => props.done ? COLORS.COMPLETE : COLORS.SECONDARY};
`
