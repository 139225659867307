import React from 'react'
import { IoIosList, IoMdGrid } from 'react-icons/io'
import styled from '@emotion/styled'

import { COLORS, FlexBox, PlainButton } from 'ui'

export const LayoutControls = ({ layout, setLayout }) => (
  <Container>
    <LayoutIcon onClick={() => setLayout('list')} isActive={layout === 'list'} >
      <IoIosList />
    </LayoutIcon>
    <LayoutIcon onClick={() => setLayout('grid')} isActive={layout === 'grid'} >
      <IoMdGrid />
    </LayoutIcon>
  </Container>
)

// Components and Styles

const Container = styled(FlexBox)`
  justify-content: flex-end;
  height:36px;
`
const iconColor = ({ isActive }) => isActive ? COLORS.PRIMARY : COLORS.SECONDARY
const LayoutIcon = styled(PlainButton)`
  display: inline-flex;
  font-size: 36px;
  margin-left: 12px;
  color: ${iconColor};
  height: 1em;
`
