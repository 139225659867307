import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Sticky, StickyContainer } from 'react-sticky'

import { COLORS } from '../constants/COLORS'

export const Panel = styled(StickyContainer)`
  position:relative;
  margin:0 32px 16px 0;
  background-color:${COLORS.BACKGROUND};
  box-shadow:0 4px 8px rgba(0,0,0,0.12);
  border-radius:3px;
`

const borderColor = ({ divider }) => divider ? COLORS.TERTIARY : 'transparent'

Panel.Section = styled.div`
  position:relative;
  padding:36px;
  border-bottom:1px solid ${borderColor};
`

Panel.Header = ({ children, ...props }) => (
  <Sticky {...props}>
    {({ isSticky, style }) => (
      <HeaderBar isSticky={isSticky} style={style}>
        {children}
      </HeaderBar>
    )}
  </Sticky>
)

const HeaderBar = styled.div`
  position:relative;
  height:75px;
  z-index:1;
  padding:0 32px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  background-color: ${COLORS.BACKGROUND};
  transition:box-shadow 200ms ease;
  ${props => props.isSticky && css`
    box-shadow: 0 4px 4px rgba(0,0,0,0.08);
  `}
`