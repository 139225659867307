import { gql } from 'apollo-boost'

import { LearningObjectInfo } from './fragments'

export const CourseLearningObjectsQuery = gql`
  ${LearningObjectInfo}
  query CourseLearningObjects($id: ID!, $first: Int, $after: String, $search: String) {
    node (id: $id) {
      id
      ...on Course {
        learningObjects(first: $first, after: $after, search: $search) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              ...LearningObjectInfo
            }
          }
        }
      }
    }
  }
`

CourseLearningObjectsQuery.edgePath = 'node.learningObjects'