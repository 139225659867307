import { RequestProcessedSubscription } from 'gql'
import client from 'apollo'

/* -------------------------------------------------------------------------- *
 * submit
 *
 * Condenses the process of submitting a mutation, setting up a
 * requestProcessed subscription, and resolving or rejecting with a result.
 * -------------------------------------------------------------------------- */
export const submit = async (values, mutate) => {
  const { data } = await mutate(values)
  const { requestID } = data.result
  return new Promise((resolve, reject) => {
    const ws = client
      .subscribe({
        fetchPolicy: 'network-only',
        query: RequestProcessedSubscription,
        variables: { requestID }
      })
      .subscribe(({ data }) => {
        ws.unsubscribe()
        if (data.requestProcessed.status === 'SUCCESS') {
          resolve()
        } else {
          reject(data.requestProcessed.error)
        }
      })
  })
}