import { gql } from 'apollo-boost'

import { CurriculumInfo } from './fragments'

export const CurriculumQuery = gql`
  ${CurriculumInfo}
  query Curriculum($id: ID!) {
    node(id: $id) {
      ...CurriculumInfo
    }
  }
`