import * as yup from 'yup'
import uuid from 'uuid/v4'

const createImageAssetModel = (asset) => ({
  originalId: asset ? asset.id : null,
  originalUrl: asset ? asset.url : null,
  id: asset ? asset.id : null,
  url: asset ? asset.url : null,
  asset: null
})

export const CourseFormModel = {
  validationSchema: yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required('Please enter a course name.'),
    description: yup.string(),
    isSequential: yup.boolean(),
    heroImage: yup.object(),
    bannerImage: yup.object(),
    tags: yup.array(),
    learningObjectEdges: yup.array()
  }),
  initialValues: () => ({
    id: uuid(),
    name: '',
    description: '',
    isSequential: false,
    heroImage: createImageAssetModel(),
    bannerImage: createImageAssetModel(),
    tags: [],
    learningObjectEdges: []
  }),
  format: (node) => ({
    id: node.id,
    name: node.name,
    description: node.description || '',
    isSequential: node.isSequential,
    heroImage: createImageAssetModel(node.heroImage),
    bannerImage: createImageAssetModel(node.bannerImage),
    tags: node.tags,
    learningObjectEdges: []
  }),
  parse: (form) => ({
    variables: {
      input: {
        id: form.id,
        name: form.name,
        description: form.description || null,
        isSequential: form.isSequential,
        heroImageID: form.heroImage.id,
        bannerImageID: form.bannerImage.id,
        tags: form.tags,
        learningObjectEdges: form.learningObjectEdges,
        assets: [
          ...(form.heroImage.asset ? [form.heroImage.asset] : []),
          ...(form.bannerImage.asset ? [form.bannerImage.asset] : [])
        ]
      }
    }
  })
}