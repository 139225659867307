import React from 'react'
import styled from '@emotion/styled'

import { StatusLight } from 'ui'

export const CurriculumStatusBar = ({ wasCreated, hasCourses }) => (
  <Container>
    <StatusLight done={wasCreated}>Curriculum Created</StatusLight>
    <StatusLight done={hasCourses}>Courses Assigned</StatusLight>
  </Container>
)

// Components and Styles

const Container = styled.div`
  display:flex;
  justify-content:space-around;
  margin-top:40px;
`
