import React from 'react'

import { Sidebar } from 'components'
import { Panel } from 'ui'

export const Reports = () => (
  <Panel>
    <Panel.Section>
      <p>Reports view</p>
    </Panel.Section>
  </Panel>
)

Reports.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Use these reports to make decisions about how your users are learning.
    </Sidebar.Message>
  </Sidebar.Container>
)