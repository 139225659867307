import React, { useState } from 'react'
import { Link } from 'react-navi'
import styled from '@emotion/styled'
import { get } from 'lodash'

import { CreateLink, LayoutControls, Results, SearchBar, Sidebar } from 'components'
import { COLORS, Panel, Title } from 'ui'
import { LearningObjectsQuery } from 'gql'
import { useDebouncedValue, useSimpleQuery } from 'utils'

export const LearningObjectList = () => {
  const [search, setSearch] = useState('')
  const [layout, setLayout] = useState('list')
  const debouncedSearch = useDebouncedValue(search)

  return (
    <Panel>

      <Panel.Header>
        <Title>Learning Objects</Title>
        <CreateLink href='./new'>Create Learning Object</CreateLink>
      </Panel.Header>

      <Panel.Section>
        <LayoutOptions>
          <SearchBar placeholder='Learning Object Name' setSearch={setSearch} />
          <LayoutControls layout={layout} setLayout={setLayout} />
        </LayoutOptions>

        <Results query={LearningObjectsQuery} search={debouncedSearch}>
          {nodes => nodes.map(node => (
            <Listing key={node.id} href={`./${node.id}`}>
              <p>{node.id} - {node.name}</p>
            </Listing>
          ))}
        </Results>

      </Panel.Section>
    </Panel>
  )
};

// Components and Styles

const LayoutOptions = styled.div`
  display:flex;
  margin-bottom:40px;
`
const Listing = styled(Link)`
  display:block;
  padding:16px;
  background-color:${COLORS.TERTIARY};
  margin:0 0 8px;
  color:${COLORS.TERTIARY_TEXT};
  &:hover {
    color:${COLORS.TERTIARY_TEXT};
  }
`

LearningObjectList.Sidebar = () => {
  const [data, loading, errors] = useSimpleQuery(LearningObjectsQuery)
  const total = get(data, 'viewer.learningObjects.totalCount', 0)
  return errors ? errors[0].message : (
    <Sidebar.Container>
      <Sidebar.Dewey />
      <Sidebar.Message loading={loading}>
        You currently have {total} learning objects across all projects.
        <br /><br />
        Would you like to create another?
      </Sidebar.Message>
      <Sidebar.Action href='./new'>Create Learning Object</Sidebar.Action>
    </Sidebar.Container>
  )
}
