import React, { useState } from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'

import { CourseListing, CreateLink, LayoutControls, Results, SearchBar, Sidebar } from 'components'
import { Panel, Title } from 'ui'
import { CoursesQuery } from 'gql'
import { useDebouncedValue, useSimpleQuery } from 'utils'

export const CourseList = () => {
  const [search, setSearch] = useState('')
  const [layout, setLayout] = useState('list')
  const debouncedSearch = useDebouncedValue(search)

  return (
    <Panel>

      <Panel.Header>
        <Title>Courses</Title>
        <CreateLink href='./new'>Create Course</CreateLink>
      </Panel.Header>

      <Panel.Section>
        <LayoutOptions>
          <SearchBar placeholder='Course Name' setSearch={setSearch} />
          <LayoutControls layout={layout} setLayout={setLayout} />
        </LayoutOptions>

        <Results query={CoursesQuery} search={debouncedSearch}>
          {nodes => (
            <CourseListing.Container layout={layout}>
              {nodes.map(node => (
                <CourseListing
                  course={node}
                  href={`./${node.id}`}
                  key={node.id}
                  layout={layout}
                />
              ))}
            </CourseListing.Container>
          )}
        </Results>

      </Panel.Section>
    </Panel>
  )
}

// Components and Styles

const LayoutOptions = styled.div`
  display:flex;
  margin-bottom:40px;
`

// Sidebar

CourseList.Sidebar = () => {
  const [data, loading, errors] = useSimpleQuery(CoursesQuery)
  const total = get(data, 'viewer.courses.totalCount', 0)
  return errors ? errors[0].message : (
    <Sidebar.Container>
      <Sidebar.Dewey />
      <Sidebar.Message loading={loading}>
        You currently have {total} courses across all curriculums.
        <br /><br />
        Would you like to add another?
      </Sidebar.Message>
      <Sidebar.Action href='./new'>
        Create Course
      </Sidebar.Action>
    </Sidebar.Container>
  )
}