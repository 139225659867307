import React from 'react'
import { Query } from 'react-apollo'
import styled from '@emotion/styled'
import { cloneDeep, map } from 'lodash'
import { get } from 'lodash/fp'

import { useInfiniteScroll } from 'utils'

export const Results = ({ query, children, search, id, viewportRef }) => (
  <Query
    query={query}
    variables={{
      id,
      search: search || undefined
    }}
    notifyOnNetworkStatusChange
  >
    {props => (
      <RenderedResults
        edgePath={query.edgePath}
        children={children}
        viewportRef={viewportRef}
        {...props}
      />
    )}
  </Query>
)

const RenderedResults = ({ data, loading, error, fetchMore, edgePath, viewportRef, children }) => {

  const getEdges = get(edgePath)

  const [ref, block, unblock, check] = useInfiniteScroll(
    async () => {
      if (loading) return
      if (!getEdges(data)) return
      if (!getEdges(data).pageInfo.hasNextPage) return
      block()
      await fetchMore({
        variables: {
          after: getEdges(data).pageInfo.endCursor
        },
        updateQuery: (prev, { fetchMoreResult: next }) => {
          const updated = cloneDeep(next)
          getEdges(updated).edges = [
            ...(prev ? getEdges(prev).edges : []),
            ...getEdges(next).edges
          ]
          return updated
        }
      })
      unblock()
      check()
    },
    viewportRef
  )

  if (loading && !getEdges(data)) {
    return <Container isLoading />
  }

  if (error) {
    return <Container>Error: {error.message}</Container>
  }

  check()

  const nodes = map(getEdges(data) && getEdges(data).edges, 'node') || []
  
  return (
    <Container ref={ref}>
      {children(nodes)}
    </Container>
  )
}

// Components and Styles

const Container = styled.div`
  min-height:400px;
  transition:opacity 500ms ease;
  opacity: ${props => props.isLoading ? '0' : '1'};
`