import React from 'react'
import styled from '@emotion/styled'
import { Field } from 'formik'
import {
  MdAttachFile,
  MdLink,
  MdSchool,
  MdTextFormat,
  MdVideoLibrary
} from 'react-icons/md'

import { Button, COLORS } from 'ui'

export const LearningObjectFormatField = ({ name }) =>  (
  <Field name={name}>
    {({ field, form }) => (
      <Container>
        {FORMAT_OPTIONS.map(({ value, label, icon }) => (
          <FormatOption key={value} active={field.value === value}>
            <Button variant='icon' active={field.value === value} onClick={() => form.setFieldValue(name, value)}>
              {icon}
            </Button>
            {label}
          </FormatOption>
        ))}
      </Container>
    )}
  </Field>
)


// Constants

const FORMAT_OPTIONS = [{
  value: 'scorm',
  label: 'Scorm',
  icon: <MdSchool />
}, {
  value: 'link',
  label: 'Link',
  icon: <MdLink />
}, {
  value: 'file',
  label: 'File',
  icon: <MdAttachFile />
}, {
  value: 'video',
  label: 'Video',
  icon: <MdVideoLibrary />
}, {
  value: 'text',
  label: 'Text',
  icon: <MdTextFormat />
}]


// Components and Styles

const Container = styled.div`
  display:flex;
  margin:32px 16px;
  justify-content:space-between;
`
const FormatOption = styled.div`
  font-size:14px;
  color:${props => props.active ? COLORS.PRIMARY : COLORS.SECONDARY};
  font-weight:${props => props.active ? '700' : '300'};
  text-align:center;
`
