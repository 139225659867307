import React from 'react'

/* -------------------------------------------------------------------------- *
 * withRef
 *
 * HOC to convert refs into props and back to refs, in order to "skip" other
 * HOCs that don't implement React.forwardRef.
 * 
 * Example usage:
 * 
 * // Make our blue checkbox reffable, even though withProps normally would
 * // "swallow" it by applying it to an HOC that does not render any DOM.
 * const MyBlueCheckbox = compose(
 *   withRef.store(),
 *   withProps({ type: 'checkbox' }),
 *   withStyles({ border: 'blue' }),
 *   withRef.apply()
 * )(input)
 * 
 * // Now we can slap a ref on MyBlueCheckbox and get access to the underlying
 * // input.
 * const MyField = () => {
 *   const ref = useRef()
 *   const focusCheckbox = () => ref.current.focus()
 *   return (
 *     <>
 *       <MyBlueCheckbox ref={ref} /> Check it
 *       <button onClick={focusCheckbox}> Focus it
 *     </>
 *   )
 * }
 * -------------------------------------------------------------------------- */
export const withRef = {
  store: () => (Cpt) => React.forwardRef((props, ref) => (
    <Cpt {...props} forwardedRef={ref} />
  )),
  apply: () => (Cpt) => ({ forwardedRef, ...props }) => (
    <Cpt {...props} ref={forwardedRef} />
  )
}