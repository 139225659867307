import React from 'react'
import { FieldArray } from 'formik'
import styled from '@emotion/styled'

import { Tag, TextInput } from 'ui'

export const TagsField = ({ name, ...props }) => {

  const createKeyDownHandler = (push) => (e) => {
    if (e.keyCode === 13) {
      push(e.target.value.toLowerCase())
      e.target.value = ''
      e.preventDefault() // Prevent form submit
    }
  }
  
  return (  
    <FieldArray name={name}>
      {({ form, push, remove }) => (
        <>
          <TextInput onKeyDown={createKeyDownHandler(push)} {...props} />
          <TagContainer>
            {form.values[name].map((tag, i) => (
              <Tag key={tag} onDelete={() => remove(i)}>
                {tag}
              </Tag>
            ))}
          </TagContainer>
        </>
      )}
    </FieldArray>
  )
}


// Components and Styles

const TagContainer = styled.div`
  margin-top:8px;
`