import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { MdCheckCircle, MdModeEdit, MdWarning } from 'react-icons/md'
import { Link } from 'react-navi'
import { truncate } from 'lodash'

import { COLORS, Icon } from 'ui'

export const CurriculumListing = ({ curriculum, layout, href }) => (
  <Container layout={layout}>
    <Title layout={layout} title={curriculum.name} href={href}>
      {curriculum.name}
    </Title>
    <Description layout={layout} title={curriculum.description}>
      {truncate(curriculum.description, { length: 160, separator: ' ' })}
    </Description>
    <DataCanvas layout={layout} />
    <DataLabel layout={layout} style={{ gridArea: 'statusLabel' }}>
      Status
    </DataLabel>
    <DataPoint layout={layout} style={{ gridArea: 'status' }}>
      {curriculum.courses.totalCount ? (
        <CompleteIcon>
          <MdCheckCircle />
        </CompleteIcon>
      ) : (
        <IncompleteIcon>
          <MdWarning />
        </IncompleteIcon>
      )}
    </DataPoint>
    <DataLabel layout={layout} style={{ gridArea: 'coursesLabel' }}>
      Courses
    </DataLabel>
    <DataPoint layout={layout} style={{ gridArea: 'courses' }}>
      {curriculum.courses.totalCount ? (
        `${curriculum.courses.totalCount} Courses`
      ) : (
        <IncompleteIcon>
          <MdWarning />
        </IncompleteIcon>
      )}
    </DataPoint>
    <DataLabel layout={layout} style={{ gridArea: 'durationLabel' }}>
      Duration
    </DataLabel>
    <DataPoint layout={layout} style={{ gridArea: 'duration' }}>
      {curriculum.duration ? `${curriculum.duration} Days` : 'Open'}
    </DataPoint>
    <EditLink layout={layout} href={href}>
      <MdModeEdit />
    </EditLink>
  </Container>
)

CurriculumListing.Container = styled.div`
  display:grid;
  grid-column-gap:32px;
  grid-row-gap: ${props => props.layout === 'list' ? '8px' : '32px'};
  grid-template-columns: ${props => props.layout === 'list' ? '1fr' : 'repeat(auto-fill, minmax(210px, 1fr))'};
`

// Components and Styles

const Container = styled.div`
  position:relative;
  display:grid;
  grid-column-gap:8px;
  box-shadow:0 2px 4px rgba(0,0,0,0.16);
  ${props => props.layout === 'list' && css`
    background-color:${COLORS.BACKGROUND_ACCENT};
    width:100%;
    height:60px;
    grid-template-columns: 1fr 4fr 2fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
      "status title courses duration edit"
      "status desc courses duration edit";
    grid-column-gap:32px;
  `}
  ${props => props.layout === 'grid' && css`
    background-color:${COLORS.BACKGROUND};
    width:auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 40px 40px 40px;
    grid-template-areas: 
      "title title"
      "desc desc"
      "statusLabel status"
      "coursesLabel courses"
      "durationLabel duration";
  `}
`
const Title = styled(Link)`
  grid-area:title;
  line-height:1.2;
  ${props => props.layout === 'list' && css`
    font-size:14px;
    color:${COLORS.NEUTRAL};
    align-self:end;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    margin-top:4px;
    &:hover {
      color:${COLORS.NEUTRAL};
      text-decoration:underline;
    }
  `}
  ${props => props.layout === 'grid' && css`
    padding:16px 0 0 16px;
    margin-right:56px;
    color:${COLORS.PRIMARY_ACCENT};
    font-size:23px;
    font-weight:300;
    &:hover {
      color:${COLORS.PRIMARY_ACCENT};
      text-decoration:underline;
    }
  `}
`
const Description = styled.p`
  grid-area:desc;
  overflow:hidden;
  ${props => props.layout === 'list' && css`
    align-self:start;
    padding-top:4px;
    font-size:12px;
    color:${COLORS.NEUTRAL};
    white-space:nowrap;
    text-overflow:ellipsis;
  `}
  ${props => props.layout === 'grid' && css`
    align-self:end;
    position:relative;
    font-size:14px;
    color:${COLORS.SECONDARY};
    padding:16px 16px 0;
    margin-bottom:16px;
    line-height:1.2;
  `}
`
const DataLabel = styled.label`
  font-size:16px;
  line-height:1.2;
  color:${COLORS.NEUTRAL};
  align-self:center;
  font-weight:600;
  padding:8px 16px;
  ${props => props.layout === 'list' && css`
    position:absolute;
    visibility:hidden;
  `}
`
const DataPoint = styled.p`
  font-size:12px;
  color:${COLORS.NEUTRAL};
  line-height:1.2;
  align-self:center;
  ${props => props.layout === 'list' && css`    
    text-align:center;
  `}
  ${props => props.layout === 'grid' && css`
    text-align:left;
    padding-top:4px;
    padding-right:16px;
  `}
`
const EditLink = styled(Link)`
  height:1em;
  ${props => props.layout === 'list' && css`    
    grid-area:edit;
    align-self:center;
    font-size:14px;
    color:${COLORS.NEUTRAL};
    &:hover {
      color:${COLORS.NEUTRAL_ACCENT};
    }
  `}
  ${props => props.layout === 'grid' && css`
    position:absolute;
    color:${COLORS.NEUTRAL};
    top:16px;
    right:16px;
    font-size:24px;
    &:hover {
      color:${COLORS.NEUTRAL_ACCENT};
    }
  `}
`
const DataCanvas = styled.div`
  background-color:${COLORS.BACKGROUND_ACCENT};
  ${props => props.layout === 'list' && css`    
    grid-area: 1 / 3 / 3 / 6
  `}
  ${props => props.layout === 'grid' && css`
    grid-area: 3 / 1 / 6 / 3
  `}
`
const CompleteIcon = styled(Icon)`
  color:${COLORS.COMPLETE};
  font-size:24px;
  line-height:0;
`
const IncompleteIcon = styled(Icon)`
  color:${COLORS.DESTRUCTIVE};
  font-size:24px;
  line-height:0;
`