import { gql } from 'apollo-boost'

import { CourseInfo } from './fragments'

export const CurriculumCoursesQuery = gql`
  ${CourseInfo}
  query CurriculumCourses($id: ID!, $first: Int, $after: String, $search: String) {
    node (id: $id) {
      id
    ... on Curriculum {
      courses(first: $first, after: $after, search: $search) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...CourseInfo
          }
        }
      }
    }}
  }
`

CurriculumCoursesQuery.edgePath = 'node.courses'