import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-navi'
import { IoMdAdd } from 'react-icons/io'

import { COLORS, FONT_SIZES, Icon } from 'ui'

export const CreateLink = ({ href, children, ...props }) => (
  <StyledLink href={href} {...props}>
    <CreateIcon>
      <IoMdAdd />
    </CreateIcon>
    <CreateText>
      {children}
    </CreateText>
  </StyledLink>
)

// Components and Styles

const StyledLink = styled(Link)`
  display:flex;
  height:calc(${FONT_SIZES.XXLARGE} + 8px);
  color:${COLORS.PRIMARY};
  line-height:${FONT_SIZES.XXLARGE};
  &:hover {
    color:${COLORS.PRIMARY};
  }
`
const CreateIcon = styled(Icon)`
  display:block;
  flex-grow:0;
  font-size:${FONT_SIZES.XXLARGE};
`
const CreateText = styled.span`
  display:block;
  line-height:calc(${FONT_SIZES.XXLARGE} + 4px);
  font-size:${FONT_SIZES.LARGE};
  font-weight:300;
  padding-left:4px;
  text-align:left;
`
