import React from 'react'
import { IoMdSearch } from 'react-icons/io'
import styled from '@emotion/styled'

import { COLORS, Icon, TextInput } from 'ui'


export const SearchBar = ({ setSearch, placeholder = '' }) => (
  <Container>
    <SearchIcon>
      <IoMdSearch />
    </SearchIcon>
    <SearchInput
      placeholder={placeholder}
      onInput={e => setSearch(e.target.value)}
      variant='minimal'
    />
  </Container>
)

// Components and Styles

const Container = styled.div`
  display:flex;
  width:100%;
`
const SearchIcon = styled(Icon)`
  flex-grow:0;
  font-size:28px;
  color:${COLORS.SECONDARY_ACCENT};
  margin-right:8px;
  margin-bottom:4px;
`
const SearchInput = styled(TextInput)`
  margin-right:32px;
`
