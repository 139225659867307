import { gql } from 'apollo-boost'

import { CourseInfo } from './fragments'

export const CoursesQuery = gql`
  ${CourseInfo}
  query Courses($first: Int, $after: String, $search: String) {
    viewer {
      courses(first: $first, after: $after, search: $search) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges { 
          cursor
          node {
            ...CourseInfo       
          }
        }
      }
    }
  }
`

CoursesQuery.edgePath = 'viewer.courses'
