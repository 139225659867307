import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { Grid, Col, COLORS, FONT_SIZES } from 'ui'
import { Breadcrumbs } from '../Breadcrumbs'
import { Logo } from '../Logo'

export const ToolBar = () => (  
  <Container>
    <Left area={[1,4]}>
      <Logo />
      Vaccines.com
    </Left>
    <Right area={[5,12]}>
      <Breadcrumbs />
    </Right>
  </Container>
)

const Container = styled(Grid)`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:70px;
  background-color:${COLORS.PRIMARY};
  z-index:1;
`
const colStyles = css`
  display:flex;
  align-items:center;
  height:100%;
  padding:16px 32px;
  color:${COLORS.PRIMARY_TEXT};
`
const Left = styled(Col)`
  ${colStyles}
  font-family:'Roboto Condensed', sans-serif;
  font-size:${FONT_SIZES.XXLARGE};
  justify-content:center;
`
const Right = styled(Col)`
  ${colStyles}
  background-color:${COLORS.PRIMARY_ACCENT};
`