import React from 'react'
import styled from '@emotion/styled'

import {
  CreateLink,
  EdgeField,
  FormError,
  ImageUploadField,
  SwitchField,
  TagsField,
  TextAreaField,
  TextInputField
} from 'components'
import { COLORS, FONT_SIZES, Panel } from 'ui'
import { LearningObjectsQuery, CourseLearningObjectsQuery } from 'gql'

export const CourseForm = ({ values }) => (
  <>

    <Panel.Section divider>

      <Heading>Info</Heading>

      <FormField>
        <TextInputField name='name' placeholder='Course Name' />
        <FormError name='name' />
      </FormField>

      <FormField>
        <TextAreaField name='description' placeholder='Course Description' />
      </FormField>

    </Panel.Section>

    <Panel.Section divider>
      <CourseGrid>

        <div style={{ gridArea: 'tags', paddingTop: 8 }}>
          <Heading>Tags</Heading>
          <TagsField name='tags' />
        </div>

        <div style={{ gridArea: 'media', paddingTop: 8 }}>
          <Heading>Media</Heading>
          <Legend>Choose one hero image and one banner image for this course.</Legend>
        </div>
        <div style={{ gridArea: 'hero' }}>
          <FieldLabel>Hero Image</FieldLabel>
          <ImageUploadField name='heroImage' />
        </div>
        <div style={{ gridArea: 'banner' }}>
          <FieldLabel>Banner Image</FieldLabel>
          <ImageUploadField name='bannerImage' />
        </div>

      </CourseGrid>
    </Panel.Section>

    <Panel.Section>

      <Heading>Learning Objects</Heading>
      <CreateLink href='../../learning-objects/new' style={{ position:'absolute', top:32, right:32 }}>
        Create Learning Project
      </CreateLink>

      <FormField>
        <div style={{ width: '50%', display: 'flex' }}>
          <div style={{ flexShrink: 0 }}>
            <SwitchField name='isSequential' />
          </div>
          <Legend style={{ marginTop:0, marginLeft:16 }}>Are the learning objects required to be taken in order?</Legend>
        </div>
      </FormField>

      <EdgeField
        name='learningObjectEdges'
        connectedQuery={CourseLearningObjectsQuery}
        connectedArgs={{ id: values.id }}
        availableQuery={LearningObjectsQuery}
        canReorder={values.isSequential}
      />

    </Panel.Section>
  </>
)


// Components and Styles

const FormField = styled.div`
  margin-bottom:16px;
`
const Heading = styled.h2`
  font-size:${FONT_SIZES.LARGE};
  color:${COLORS.PRIMARY};
  font-weight:700;
  margin-top:0;
  margin-bottom:16px;
`
const Legend = styled.p`
  font-size:14px;
  font-weight:300;
  margin-top:8px;
  line-height:1.3;
`
const CourseGrid = styled.div`
  display:grid;
  grid-template-columns:14fr 2fr 4fr 1fr 9fr;
  grid-template-rows:auto;
  grid-template-areas:
    "tags . media media media"
    "tags . hero . banner";
`
const FieldLabel = styled.p`
  margin:20px 0 12px;
  font-size:14px;
  color:${COLORS.SECONDARY_ACCENT};
`
