import React from 'react'
import { ClassNames } from '@emotion/core'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { COLORS } from '../constants/COLORS'
import { TextInput } from './TextInput'

export const DateInput = ({ value, onChange, dateFormat, disabled, placeholder, variant, className }) => (
  <ClassNames>
    {({ css }) => {
      // The version of css provided by ClassNames is different from that
      // exported from @emotion/core; it generates a className rather than an
      // object.
      const calendarClassName = css`
        border:1px solid ${COLORS.TERTIARY};
        box-shadow:0 4px 4px rgba(0,0,0,0.08);

        .react-datepicker__header {
          background-color:${COLORS.BACKGROUND_ACCENT};
          border-bottom:0;
          box-shadow:0 4px 4px rgba(0,0,0,0.08);
        }

        .react-datepicker__day--selected {
          background-color:${COLORS.PRIMARY_ACCENT};
          color:${COLORS.PRIMARY_TEXT};
        }

        .react-datepicker__day--keyboard-selected {
          background-color:${COLORS.SECONDARY};
          color:${COLORS.SECONDARY_TEXT};
        }

        .react-datepicker__triangle::before {
          border-color:${COLORS.TERTIARY};
        }
      `
      
      return (
        <DatePicker
          selected={value}
          onChange={(dateObj) => onChange(dateObj)}
          customInput={<TextInput variant={variant} />}
          calendarClassName={calendarClassName}
          className={className}
          disabled={disabled}
          dateFormat={dateFormat || 'M/d/YYYY'}
          placeholderText={placeholder}
        />
      )
    }}
  </ClassNames>
)
