import { gql } from 'apollo-boost'

export const CourseInfo = gql`
  fragment CourseInfo on Course {
    id
    name
    description
    tags
    isSequential
    learningObjects {
      totalCount
    }
    heroImage {
      id
      url
    }
    bannerImage {
      id
      url
    }
  }
`