import { gql } from 'apollo-boost'

import { LearningObjectInfo } from './fragments'

export const LearningObjectQuery = gql`
  ${LearningObjectInfo}
  query LearningObject($id: ID!) {
    node(id: $id) {
      ...LearningObjectInfo
    }
  }
`