import React from 'react'
import styled from '@emotion/styled'
import { withProps } from 'recompose'

import { Box, COLORS } from 'ui'

export const Logo = () => (
  <Container>
    <Learning />
    <Everywhere />
  </Container>
)

const Container = styled(Box)`
  display:flex;
  flex-direction:column;
  text-transform:uppercase;
  padding-right:8px;
  margin-right:8px;
  border-right:1px solid ${COLORS.PRIMARY_TEXT};
`
const Learning = withProps({ children: 'Learning' })(styled.span`
  font-family:'Roboto Condensed', sans-serif;
  font-size:16px;
`)
const Everywhere = withProps({ children: 'Everywhere' })(styled.span`
  font-family:'Roboto Condensed', sans-serif;
  font-size:12px;
`)
