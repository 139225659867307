import { gql } from 'apollo-boost'

import { LearningObjectInfo } from './fragments'

export const LearningObjectsQuery = gql`
  ${LearningObjectInfo}
  query LearningObjects($first: Int, $after: String, $search: String) {
    viewer {
      learningObjects(first: $first, after: $after, search: $search) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...LearningObjectInfo
          }
        }
      }
    }
  }
`

LearningObjectsQuery.edgePath = 'viewer.learningObjects'