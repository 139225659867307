import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'

import './CreateUser.scss';
      
export class CreateUser extends React.Component {
  state = {
    email: '',
    password: ''
  };

  render() {
    let input  = this.state;
    return (   
      <div id="create-user-form">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-12">
              <h5>Create User</h5>
            </div>
          </div>
              <Form
              className="create-user"
              onSubmit={e => {
                e.preventDefault();
                // this.clearState();
              }}>

              <Form.Group controlId="contact-email">
                <Form.Control                       
                  value={input.email}
                  onChange={e => this.setState({...input,  email: e.target.value })}
                  type="text" placeholder="Enter the contact Email" />
                <Form.Text>
                  Enter a valid email address.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Control                       
                  value={input.password}
                  onChange={e => this.setState({...input,  password: e.target.value })}
                  type="text" placeholder="Enter a password" />              
                  <Form.Text>
                    Enter your password.
                  </Form.Text>
              </Form.Group>
              <div className="container-fluid form-buttons">
                <div className="row">
                  <div className="col-12 text-right">
                  <Button variant="secondary" type="secondary">Cancel</Button>
                  <Button variant="primary" type="submit">Create Account</Button>
                  </div>
                </div>
              </div>

            </Form>

        </div>
      </div>
    );
  }
};