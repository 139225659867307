import React from 'react'
import { Link, useNavigation } from 'react-navi'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { Mutation } from 'react-apollo'

import { CurriculumForm } from 'views'
import { CurriculumStatusBar, Sidebar } from 'components'
import { Button, Panel, Title } from 'ui'
import { CurriculumFormModel } from 'formModels'
import { CurriculumMutation } from 'gql'
import { submit } from 'utils'

const { validationSchema, initialValues, parse } = CurriculumFormModel

export const CurriculumCreate = () => {
  const navigation = useNavigation()
  return (
    <Mutation mutation={CurriculumMutation('CREATE')}>
      {mutate => (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues()}
          onSubmit={async (values) => {
            try {
              await submit(parse(values), mutate)
              navigation.navigate('.')
            } catch (err) {
              alert('Whoopsie', err)
            }
          }}
        >
          {formikProps => (
            <Form>
              <Panel>

                <Panel.Header>
                  <Title>New Curriculum</Title>
                  <ControlBox>
                    <BackLink variant='discard' href='..'>Back</BackLink>
                    <Button variant='persist' type='submit'>Save</Button>
                  </ControlBox>
                </Panel.Header>

                <CurriculumStatusBar
                  wasCreated={false}
                  hasCourses={false}
                />

                <CurriculumForm {...formikProps} />
              </Panel>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>
  )
}

// Components and Styles

const BackLink = Button.withComponent(Link)

const ControlBox = styled.div`
  display:flex;
  width:180px;
  justify-content:space-between;
`

// Sidebar

CurriculumCreate.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Enter details about your new curriculum. You can edit these later.
    </Sidebar.Message>
  </Sidebar.Container>
)