import { gql } from 'apollo-boost'

import { CourseInfo } from './fragments'

export const CourseQuery = gql`
  ${CourseInfo}
  query Course($id: ID!) {
    node(id: $id) {
      ...CourseInfo
    }
  }
`