import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'

const GET_ACCOUNTS = gql`
  {
    accounts {
      id
      businessName
      contactName
      contactEmail
      password
    }
  }
`;

const ADD_ACCOUNT = gql`
  mutation createAccount($input: AccountInput!) {
    addAccount(input: $input) {
      id
      businessName
      contactName
      contactEmail
      password
    }
  }
`;

export class CreateAccount extends React.Component {
  state = {
    businessName: '',
    contactName: '',
    contactEmail: '',
    password: ''
  };

  clearState() {
    this.setState(Object.assign(
      this.state, 
      {businessName: ''}, 
      {contactName: ''}, 
      {contactEmail: ''}, 
      {password: ''}
    ));
  }

  render() {
    let input  = this.state;
    return (
      <Mutation 
        mutation={ ADD_ACCOUNT }
        variables={{ input }}
        update={(cache, { data: { addAccount } }) => {
          const { accounts } = cache.readQuery({ query: GET_ACCOUNTS });
          cache.writeQuery({
            query: GET_ACCOUNTS,
            data: { accounts: accounts.concat([addAccount]) },
          });
        }}
        onError={() => {}}>
        {(createAccount, result) => {
          const { loading, error } = result;          
            if (loading) {
              return <div>LOADING</div>;
            } else if (error) {
              return <div>ERROR</div>;
            } else 

            return (          
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    createAccount();
                    this.clearState();
                  }}>

                  <Form.Group controlId="business-name">
                    <Form.Control                       
                      value={input.businessName}
                      onChange={e => this.setState({...input,  businessName: e.target.value })}
                      type="text" placeholder="Enter Business Name" />
                    <Form.Text>
                      Enter your complete business name.
                    </Form.Text>
                  </Form.Group>
                  
                  <Form.Group controlId="contact-name">
                    <Form.Control  
                      value={input.contactName}
                      onChange={e => this.setState({...input,  contactName: e.target.value })}
                      type="text" placeholder="Enter Contact Name" />
                    <Form.Text>
                      Enter your full name.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="contact-email">
                    <Form.Control                       
                      value={input.contactEmail}
                      onChange={e => this.setState({...input,  contactEmail: e.target.value })}
                      type="text" placeholder="Enter the contact Email" />
                    <Form.Text>
                      Enter a valid email address.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Control                       
                      value={input.password}
                      onChange={e => this.setState({...input,  password: e.target.value })}
                      type="text" placeholder="Enter a password" />
                  </Form.Group>

                  <Button 
                    variant="primary" 
                    type="submit">
                    Create Account
                  </Button>
                </Form>
            );
        }}
      </Mutation>
    );
  }
}


