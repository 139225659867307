import { gql } from 'apollo-boost'

export const CurriculumInfo = gql`
  fragment CurriculumInfo on Curriculum {
    id
    name
    description
    isRequired
    duration
    deadline
    courses {
      totalCount
    }
  }
`