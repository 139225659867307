import React from 'react'

import styled from '@emotion/styled'
import { MdClose } from 'react-icons/md'

import { InlineBox } from './Box'
import { PlainButton } from './PlainButton'
import { COLORS } from '../constants/COLORS'
import { FONT_SIZES } from '../constants/FONT_SIZES'

export const Tag = ({ onDelete, children }) => (
  <TagRoot>
    <DeleteButton onClick={onDelete}>
      <MdClose />
    </DeleteButton>
    <InlineBox>
      { children }
    </InlineBox>
  </TagRoot>
)

// Components and Styles

const TagRoot = styled(InlineBox)`
  display:inline-flex;
  padding: 4px 8px;
  background-color: ${COLORS.SECONDARY};
  border-radius: 12px;
  font-size: ${FONT_SIZES.SMALL};
  line-height: ${FONT_SIZES.SMALL};
  font-weight: 300;
  color: ${COLORS.SECONDARY_TEXT};
  margin:4px;
`
const DeleteButton = styled(PlainButton)`
  display:inline-block;
  margin-right: 4px;
  font-size: ${FONT_SIZES.SMALL};
  height:1em;
  vertical-align:middle;
  color: ${COLORS.SECONDARY_TEXT};
  &:focus {
    outline:0;
  }
`
