import { gql } from 'apollo-boost'

export const LearningObjectInfo = gql`
  fragment LearningObjectInfo on LearningObject {
    id
    name
    description
    format
    textContent
    linkContent
    fileContent {
      id
      name
    }
    scormContent {
      id
      name
    }
    videoContent {
      id
      name
    }
    tags
 }
`