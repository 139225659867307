import React, { useState } from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'

import { CreateLink, CurriculumListing, LayoutControls, Results, SearchBar, Sidebar } from 'components'
import { Panel, Title } from 'ui'
import { CurriculumsQuery } from 'gql'
import { useDebouncedValue, useSimpleQuery } from 'utils'

export const CurriculumList = () => {
  const [search, setSearch] = useState('')
  const [layout, setLayout] = useState('list')
  const debouncedSearch = useDebouncedValue(search)

  return (
    <Panel>

      <Panel.Header>
        <Title>Curriculums</Title>
        <CreateLink href="./new">Create Curriculum</CreateLink>
      </Panel.Header>

      <Panel.Section>
        <LayoutOptions>
          <SearchBar placeholder='Curriculum Name' setSearch={setSearch} />
          <LayoutControls layout={layout} setLayout={setLayout} />
        </LayoutOptions>

        <Results query={CurriculumsQuery} search={debouncedSearch}>
          {nodes => (
            <CurriculumListing.Container layout={layout}>
              {nodes.map(node => (
                <CurriculumListing
                  key={node.id}
                  href={`./${node.id}`}
                  layout={layout}
                  curriculum={node}
                />
              ))}
            </CurriculumListing.Container>
          )}
        </Results>

      </Panel.Section>
    </Panel>
  )
}

// Components and Styles

const LayoutOptions = styled.div`
  display:flex;
  margin-bottom:40px;
`

// Sidebar

CurriculumList.Sidebar = () => {
  const [data, loading, errors] = useSimpleQuery(CurriculumsQuery)
  const total = get(data, 'viewer.curriculums.totalCount', 0)
  return errors ? errors[0].message : (
    <Sidebar.Container>
      <Sidebar.Dewey />
      <Sidebar.Message loading={loading}>
        You currently have {total} curriculums.
        <br /><br />
        Would you like to create another?
      </Sidebar.Message>
      <Sidebar.Action href='./new'>Create Curriculum</Sidebar.Action>
    </Sidebar.Container>
  )
}