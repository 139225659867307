import React from 'react'
import { Link, useNavigation } from 'react-navi'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { Mutation } from 'react-apollo'

import { CourseQuery, CourseMutation } from 'gql'
import { CourseForm } from 'views'
import { Sidebar } from 'components'
import { Button, Panel, Title } from 'ui'
import { submit, useParam, useSimpleQuery } from 'utils'
import { CourseFormModel } from 'formModels'

const { validationSchema, format, parse } = CourseFormModel

export const CourseManage = () => {
  const id = useParam('courseID')
  const [data, loading] = useSimpleQuery(CourseQuery, { id })
  const navigation = useNavigation()

  return loading || (
    <Mutation mutation={CourseMutation('UPDATE')}>
      {mutate => (
        <Formik
          validationSchema={validationSchema}
          initialValues={format(data.node)}
          onSubmit={async (values) => {
            try {
              await submit(parse(values), mutate)
              navigation.navigate('.')
            } catch (err) {
              alert('Whoopsie', err)
            }
          }}
        >
          {formikProps => (
            <Form>
              <Panel>

                <Panel.Header>
                  <Title>Edit {data.node.name}</Title>
                  <ControlBox>
                    <BackLink variant='discard' href='..'>Back</BackLink>
                    <Button variant='persist' type='submit'>Save</Button>
                  </ControlBox>
                </Panel.Header>

                <CourseForm {...formikProps} />

              </Panel>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>
  ) 
}


// Components and Styles

const BackLink = Button.withComponent(Link)

const ControlBox = styled.div`
  display:flex;
  width:180px;
  justify-content:space-between;
`

// Sidebar

CourseManage.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Choose the learning objects for this course.
      <br /><br />
      You can also create new learning objects.
    </Sidebar.Message>
    <Sidebar.Action href='../../learning-objects/new'>
      Create Learning Object
    </Sidebar.Action>
  </Sidebar.Container>
)