import { gql } from 'apollo-boost'

export const CurriculumMutation = (operation) => {
  const field = operation === 'CREATE' ? 'createCurriculum' : 'updateCurriculum'
  return gql`
    mutation CurriculumMutation($input:CurriculumInput!) {
      result: ${field} (input:$input) {
        requestID
      }
    }
  `
}