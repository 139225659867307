import styled from '@emotion/styled'
import { defaultProps } from 'recompose'

// Default to type="button" to avoid unintentional form submit
const withButtonType = defaultProps({ type: 'button' })

export const PlainButton = withButtonType(styled.button`
  display: inline-block;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  line-height: 1em;
`)
