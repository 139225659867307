import { useEffect, useState } from 'react'
import { get } from 'lodash/fp'

import client from '../apollo'

/* -------------------------------------------------------------------------- *
 * useSimpleQuery
 *
 * Bare minimum Apollo Client query in hook form. Accepts a query and a
 * variables object and returns [data, loading, errors]. Example usage:
 * 
 * const MyFetchedResource = () => {
 *   const [data, loading, errors] = useSimpleQuery(ResourceQuery, { id })
 *   if (errors) return errors[0].message
 *   return (
 *     <div>
 *       <h1>My Resource</h1>
 *       {loading && <p>Loading ...</p>}
 *       {loading || <Resource data={data} />
 *     </div>
 *   )
 * }  
 * -------------------------------------------------------------------------- */
export const useSimpleQuery = (query, variables) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState(null)

  const hasConnection = !!query.edgePath
  const getConnection = get(query.edgePath)

  const edges = []

  const makeQuery = async (variables) => {
    // Make query
    const result = await client.query({ query, variables })
    // Bail if errored
    if (result.errors) {
      setErrors(result.errors)
      return {}
    }
    if (!hasConnection) {
      return result
    }
    // Drill down to connection
    const connection = getConnection(result.data)
    // Bail if no info at all
    if (!connection) {
      return { data: null }
    }
    // Recursively get more edges if needed
    if (connection.pageInfo.hasNextPage) {
      edges.push(...connection.edges)
      return await makeQuery({
        ...variables,
        after: connection.pageInfo.endCursor
      })
    }
    // Otherwise return result filled out with all edges
    else {
      connection.edges.unshift(...edges)
      return result
    }
  }

  useEffect(() => {  
    makeQuery(variables).then(result => {
      setData(result.data)
      setLoading(false)
    })
  }, [])

  return [data, loading, errors]
}
  