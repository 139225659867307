import React from 'react'
import { Link, useNavigation } from 'react-navi'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { Mutation } from 'react-apollo'

import { LearningObjectForm } from 'views'
import { Sidebar } from 'components'
import { Button, Panel, Title } from 'ui'
import { LearningObjectFormModel } from 'formModels'
import { LearningObjectMutation } from 'gql'
import { submit } from 'utils'

const { validationSchema, initialValues, parse } = LearningObjectFormModel

export const LearningObjectCreate = () => {
  const navigation = useNavigation()
  return (
    <Mutation mutation={LearningObjectMutation('CREATE')}>
      {mutate => (    
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues()}
          onSubmit={async (values) => {
            try {
              await submit(parse(values), mutate)
              navigation.navigate('.')
            } catch (err) {
              alert('Whoopsie', err)
            }
          }}
        >
          {formikProps => (
            <Form>
              <Panel>

                <Panel.Header>
                  <Title>New Learning Object</Title>
                  <ControlBox>
                    <BackLink variant='discard' href='..'>Back</BackLink>
                    <Button variant='persist' type='submit'>Save</Button>
                  </ControlBox>
                </Panel.Header>

                <LearningObjectForm {...formikProps} />

              </Panel>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>   
  )
}

// Components and Styles

const BackLink = Button.withComponent(Link)

const ControlBox = styled.div`
  display:flex;
  width:180px;
  justify-content:space-between;
`

// Sidebar

LearningObjectCreate.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Create a learning object. Learning objects can be assigned to courses.
    </Sidebar.Message>
  </Sidebar.Container>
)