import React from 'react';
import styled from '@emotion/styled'
import { IconContext } from "react-icons";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import { FaEllipsisV } from "react-icons/fa";


export class LearningObject extends React.Component {
  render() {

    return (
      <Container>
        {!this.props.isAdded ? <IconContext.Provider value={{ style: { width: '8px' } }}>
          <FaEllipsisV color="#bbbaba" />
          <FaEllipsisV color="#bbbaba" />
        </IconContext.Provider>
          : ''}
        <ObjectName>
          {this.props.learnObject.name}
        </ObjectName>
        <IconAlign>
          {this.props.isAdded ? <MdAddCircleOutline color="#f38350" /> : <MdRemoveCircleOutline color="#d4511c" />}
        </IconAlign>
      </Container>
    );
  }
};

const Container = styled.div`
  padding: 10px 10px 10px 10px;
  margin: 5px 0px;
  background: #f7f7f7;
`

const ObjectName = styled.span`
  padding-left:10px;
  color: #2f4857;
  font-size: 13px;
`

const IconAlign = styled.span`
float:right;
`

export default LearningObject;