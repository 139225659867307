import React from 'react'
import { Link, useNavigation } from 'react-navi'
import { Formik, Form } from 'formik';
import styled from '@emotion/styled'
import { Mutation } from 'react-apollo'

import { CurriculumQuery, CurriculumMutation } from 'gql'
import { CurriculumForm } from 'views'
import { CurriculumStatusBar, Sidebar } from 'components'
import { Button, Panel, Title } from 'ui'
import { submit, useParam, useSimpleQuery } from 'utils'
import { CurriculumFormModel } from 'formModels'

const { validationSchema, format, parse } = CurriculumFormModel

export const CurriculumManage = () => {
  const id = useParam('curriculumID')
  const [data, loading] = useSimpleQuery(CurriculumQuery, { id })
  const navigation = useNavigation()

  return loading || (
    <Mutation mutation={CurriculumMutation('UPDATE')}>
      {mutate => (
        <Formik
          validationSchema={validationSchema}
          initialValues={format(data.node)}
          onSubmit={async (values) => {
            try {
              await submit(parse(values), mutate)
              navigation.navigate('.')
            } catch (err) {
              alert('Whoopsie', err)
            }
          }}
        >
          {formikProps => (
            <Form>
              <Panel>

                <Panel.Header>
                  <Title>Edit {data.node.name}</Title>
                  <ControlBox>
                    <BackLink variant='discard' href='..'>Back</BackLink>
                    <Button variant='persist' type='submit'>Save</Button>
                  </ControlBox>
                </Panel.Header>

                <CurriculumStatusBar
                  wasCreated={true}
                  hasCourses={!!data.node.courses.totalCount}
                />

                <CurriculumForm {...formikProps} />
              
              </Panel>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>
  )
}

// Components and Styles

const BackLink = Button.withComponent(Link)

const ControlBox = styled.div`
  display:flex;
  width:180px;
  justify-content:space-between;
`

// Sidebar

CurriculumManage.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Update your curriculum details.
    </Sidebar.Message>
  </Sidebar.Container>
)