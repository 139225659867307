import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { compose, withProps } from 'recompose'

import { COLORS } from '../constants/COLORS'
import { FONT_SIZES } from '../constants/FONT_SIZES'
import { withRef } from '../../utils'

const VARIANTS = {
  standard: css`
    border:1px solid ${COLORS.TERTIARY};
    border-radius:5px;
    box-shadow:0 2px 4px ${COLORS.TERTIARY};
  `,
  minimal: css`
    border:1px solid transparent;
    border-bottom-color: ${COLORS.SECONDARY};
  `,
  small: css`
    border:1px solid ${COLORS.TERTIARY};
    border-radius:5px;
    box-shadow:0 2px 4px ${COLORS.TERTIARY};
    font-size:14px;
  `
}

export const TextInput = compose(
  withRef.store(),
  withProps({ type: 'text' }),
  withRef.apply()
)(
  styled.input`
    appearance:none;
    display:block;
    width:100%;
    padding:8px 16px;
    box-sizing:border-box;
    font-size:${FONT_SIZES.MEDIUM};
    font-weight:300;
    line-height:1em;
    &::placeholder {
      color:${COLORS.SECONDARY_ACCENT};
      font-weight:300;
    }
    &:disabled {
      color:${COLORS.SECONDARY_ACCENT};
    }
    ${(props) => VARIANTS[props.variant || 'standard']}
  `
)
