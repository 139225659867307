import styled from '@emotion/styled'

import { COLORS } from '../constants/COLORS'
import { FONT_SIZES } from '../constants/FONT_SIZES'

export const TextArea = styled.textarea`
  appearance:none;
  display:block;
  width:100%;
  height:70px;
  padding:8px 16px;
  box-sizing:border-box;
  border:1px solid ${COLORS.TERTIARY};
  border-radius:5px;
  font-size:${FONT_SIZES.MEDIUM};
  font-weight:300;
  box-shadow:0 2px 4px ${COLORS.TERTIARY};
  resize:none;
  &::placeholder {
    color:${COLORS.SECONDARY_ACCENT};
    font-weight:300;
  }
`
