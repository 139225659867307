import { gql } from 'apollo-boost'

export const LearningObjectMutation = (operation) => {
  const field = operation === 'CREATE' ? 'createLearningObject' : 'updateLearningObject'
  return gql`
    mutation LearningObjectMutation($input:LearningObjectInput!) {
      result: ${field} (input:$input) {
        requestID
      }
    }
  `
}