import React from 'react'
import { Field } from 'formik'

import { Switch } from 'ui'

export const SwitchField = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <Switch
        checked={field.value}
        onChange={() => form.setFieldValue(name, !field.value)}
        {...props}  
      />
    )}
  </Field>
)
