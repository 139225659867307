import styled from '@emotion/styled'

export const Box = styled.div`
  min-width: 0;
`
export const FlexBox = styled(Box)`
  display:flex;
`
export const InlineBox = styled(Box)`
  display:inline-block;
`
