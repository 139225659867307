import React from 'react'
import { Link } from 'react-navi'

import './LandingPage.scss'

export const LandingPage = () => (
  <div id="landing-page" className="container">
    <div className="row">
      <div className="col text-center">
        This is the landing page
      </div>
    </div>
    <div className="row">
      <div className="col text-center">
        <Link href='/user/sign-in'>Sign In</Link>
      </div>
    </div>
  </div>
)
