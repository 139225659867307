import React from 'react'
import { Link, useCurrentRoute } from 'react-navi'
import { withProps } from 'recompose'
import styled from '@emotion/styled'

import { Sidebar } from 'components'
import { COLORS, Button, Panel } from 'ui'

export const ProjectHome = () => {
  const route = useCurrentRoute()
  const relPath = (path) => `${route.url.pathname}/${path}`
  return (
    <Panel>
      <Panel.Section>
        <Container>
          <LinkRow>
            <StyledLink href={relPath('curriculums')}>Manage<br /> Curriculums</StyledLink>
            <StyledLink href={relPath('courses')}>Manage<br /> Courses</StyledLink>
            <StyledLink href={relPath('learning-objects')}>Manage<br /> Learning Objects</StyledLink>
          </LinkRow>
          <LinkRow>
            <StyledLink href={relPath('audiences')}>Manage<br /> Audience</StyledLink>
            <StyledLink href={relPath('reports')}>View<br /> Reports</StyledLink>
          </LinkRow>
        </Container>
      </Panel.Section>
    </Panel>
  );
}

ProjectHome.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      Welcome to LEX&nbsp;LMS.<br />What would you like to do first?
    </Sidebar.Message>
  </Sidebar.Container>
)

// Components and Styles

const Container = styled.div`
  margin-top:270px;
`
const LinkRow = styled.div`
  display:flex;
  justify-content:center;
`
const StartLink = withProps({ variant: 'start' })(Button.withComponent(Link))
const StyledLink = styled(StartLink)`
  margin:10px;
  &:hover {
    color:${COLORS.ACTION_TEXT};
  }
`