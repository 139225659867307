import React from 'react'
import styled from '@emotion/styled'

import { FormError, LearningObjectFormatField, TagsField, TextInputField, TextAreaField, UploadField } from 'components'
import { COLORS, FONT_SIZES, Grid, Col, Panel } from 'ui'

export const LearningObjectForm = ({ values }) => {
  
  return (
    <Panel.Section>

      <Heading>Type of Learning</Heading>

      <input type='hidden' name='id' />

      <FieldSection>
        <LearningObjectFormatField name='format' />
      </FieldSection>

      <FieldSection>

        <FormField>
          <TextInputField name='name' placeholder='Learning Object Name' />
          <FormError name='name' />
        </FormField>

        <FormField>
          <TextAreaField name='description' placeholder='Learning Object Description' />
        </FormField>

      </FieldSection>

      <Grid cols={2}>

        <Col area={1}>
          <LearningObjectContentContainer>
            {values.format === 'text' && (<>
              <Heading>Enter Text</Heading>
              <TextAreaField name='textContent' />
              <FormError name='textContent' />
            </>)}
            {values.format === 'link' && (<>
              <Heading>Enter Link</Heading>
              <TextInputField name='linkContent' placeholder='URL' />
              <FormError name='linkContent' />
            </>)}
            {values.format === 'file' && (<>
              <Heading>Upload File</Heading>
              <StyledUploadField accept='.pdf' name='fileContent' />
              <FormError name='fileContent.asset' />
            </>)}
            {values.format === 'scorm' && (<>
              <Heading>Upload SCORM</Heading>
              <StyledUploadField accept='.zip' name='scormContent' />
              <FormError name='scormContent.asset' />
            </>)}
            {values.format === 'video' && (<>
              <Heading>Upload Video</Heading>
              <StyledUploadField accept='.mp4' name='videoContent' />
              <FormError name='videoContent.asset' />
            </>)}
          </LearningObjectContentContainer>
        </Col>

        <Col area={2}>
          <Heading>Tags</Heading>
          <TagsField name='tags' placeholder='Type to add tags' />
        </Col>

      </Grid>
    </Panel.Section>
  )
}


// Components and Styles

const FormField = styled.div`
  margin-bottom:16px;
`
const StyledUploadField = styled(UploadField)`
  height: 200px;
`
const LearningObjectContentContainer = styled.div`
  min-height:135px;
`
const Heading = styled.h2`
  font-size:${FONT_SIZES.LARGE};
  color:${COLORS.PRIMARY};
  font-weight:700;
  margin-bottom:16px;
`
const FieldSection = styled.div`
  margin-bottom:40px;
`
