import React from 'react'
import { Field } from 'formik'

import { TextArea } from 'ui'

export const TextAreaField = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <TextArea
        name={name}
        value={field.value || ''}
        onBlur={field.onBlur}
        onChange={field.onChange}
        {...props}
      />
    )}
  </Field>
)
