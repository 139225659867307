import React from 'react'

import { Sidebar } from 'components'
import { Panel } from 'ui'

export const Audiences = () => (
  <Panel>
    <Panel.Section>
      <p>Audiences view</p>
    </Panel.Section>
  </Panel>
)

Audiences.Sidebar = () => (
  <Sidebar.Container>
    <Sidebar.Dewey />
    <Sidebar.Message>
      You have an audience of 2,000 members.
    </Sidebar.Message>
  </Sidebar.Container>
)