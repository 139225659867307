import React from 'react'
import styled from '@emotion/styled'
import 'react-datepicker/dist/react-datepicker.css'

import {
  DateInputField,
  FormError,
  TextAreaField,
  TextInputField,
  SwitchField
} from 'components'
import { COLORS, FONT_SIZES, Panel } from 'ui'

export const CurriculumForm = ({ values }) => (
  <Panel.Section>

    <FormField>
      <TextInputField name='name' placeholder='Curriculum Name' />
      <FormError name='name' />
    </FormField>

    <FormField>
      <TextAreaField name='description' placeholder='Curriculum Description' />
    </FormField>

    <SwitchBoxContainer>
      <SwitchBox>
        <SwitchBoxLabel>
          Required?
          <SwitchBoxField name='isRequired' />
        </SwitchBoxLabel>
        <SwitchBoxInfo>
          Are the users of this campaign required to complete the courses in it?
        </SwitchBoxInfo>
      </SwitchBox>

      <SwitchBox>
        <SwitchBoxLabel>
          Duration?
          <SwitchBoxField name='hasDuration' />
        </SwitchBoxLabel>
        <SwitchBoxInfo>
          Is there an amount of time in which the courses in this campaign must be completed?
          <SwitchBoxSubfield isVisible={values.hasDuration}>
            <SwitchBoxSubfieldInput name='duration' variant='minimal' disabled={!values.hasDuration} />
            <SwitchBoxSubfieldLabel>Days</SwitchBoxSubfieldLabel>
          </SwitchBoxSubfield>
          <FormError name='duration' />
        </SwitchBoxInfo>
      </SwitchBox>

      <SwitchBox>
        <SwitchBoxLabel>
          Deadline?
          <SwitchBoxField name='hasDeadline' />
        </SwitchBoxLabel>
        <SwitchBoxInfo>
          Is there a date that this course needs to be completed by?
          <SwitchBoxSubfield isVisible={values.hasDeadline}>
            <SwitchBoxSubfieldDate name='deadline' variant='minimal' disabled={!values.hasDeadline} />
            <SwitchBoxSubfieldLabel>Choose Date</SwitchBoxSubfieldLabel>
          </SwitchBoxSubfield>
          <FormError name='deadline' />
        </SwitchBoxInfo>
      </SwitchBox>

    </SwitchBoxContainer>

  </Panel.Section>  
)


// Components and Styles

const FormField = styled.div`
  margin-bottom:16px;
`
const SwitchBoxContainer = styled.div`
  display:flex;
  justify-content:space-between;
  margin-top:40px;
`
const SwitchBox = styled.div`
  max-width:180px;
`
const SwitchBoxLabel = styled.div`
  display:flex;
  align-items:center;
  height:50px;
  color:${COLORS.TERTIARY_TEXT};
  font-size:${FONT_SIZES.MEDIUM};
  font-weight:400;
`
const SwitchBoxField = styled(SwitchField)`
  margin-left:16px;
`
const SwitchBoxInfo = styled.div`
  font-size:12px;
  line-height:1.3;
  color:${COLORS.TERTIARY_TEXT};
`
const SwitchBoxSubfield = styled.div`
  position:relative;
  margin-top:8px;
  top:-8px;
  display:flex;
  align-items:baseline;
  opacity:0;
  transition:all 300ms ease;
  ${props => props.isVisible && 'opacity:1;'}
  ${props => props.isVisible && 'top:0;'}
`
const SwitchBoxSubfieldInput = styled(TextInputField)`
  max-width:72px;
  text-align:center;
`
const SwitchBoxSubfieldDate = styled(DateInputField)`
  max-width:88px;
  padding-left:0;
  padding-right:0;
  text-align:center;
`
const SwitchBoxSubfieldLabel = styled.label`
  color:${COLORS.SECONDARY};
  font-size:${FONT_SIZES.SMALL};
  font-weight:300;
  padding-left:8px;
`