import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { NaviConsumer } from 'react-navi'

export class SignIn extends React.Component {
  state = {
    email: '',
    password: ''
  };

  clearState() {
    this.setState(Object.assign(
      this.state, 
      {email: ''}, 
      {password: ''}
    ));
  }

  render() {
    let input  = this.state;
      return (
        <NaviConsumer>
          {navigation => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                navigation.navigate('/admin/projects/sanofi');
                this.clearState();
              }}>

              <Form.Group controlId="contact-email">
                <Form.Control                       
                  value={input.email}
                  onChange={e => this.setState({...input,  email: e.target.value })}
                  type="text" placeholder="Enter the contact Email" />
                <Form.Text>
                  Enter a valid email address.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Control                       
                  value={input.password}
                  onChange={e => this.setState({...input,  password: e.target.value })}
                  type="text" placeholder="Enter a password" />              
                  <Form.Text>
                    Enter your password.
                  </Form.Text>
              </Form.Group>

              <Button 
                variant="primary" 
                type="submit">
                Create Account
              </Button>
            </Form>
          )}
        </NaviConsumer>
      );
  }
}
