const COLORS = Object.freeze({
  PRIMARY            : '#263946',
  PRIMARY_ACCENT     : '#2f4857',
  PRIMARY_TEXT       : '#ffffff',
  SECONDARY          : '#acb6bc',
  SECONDARY_ACCENT   : '#b3b3b3',
  SECONDARY_TEXT     : '#ffffff',
  TERTIARY           : '#ebebeb',
  TERTIARY_ACCENT    : '#d2dee6',
  TERTIARY_TEXT      : '#919191',
  BACKGROUND         : '#ffffff',
  BACKGROUND_ACCENT  : '#f7f7f7',
  ACTION             : '#d4511c',
  ACTION_ACCENT      : '#f38756',
  ACTION_TEXT        : '#ffffff',
  NEUTRAL            : '#707070',
  NEUTRAL_ACCENT     : '#939393',
  NEUTRAL_TEXT       : '#ffffff',
  COMPLETE           : '#56c467',
  DESTRUCTIVE        : '#b43e15',
  TEXT               : '#000000'
})

export { COLORS }