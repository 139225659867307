import React, { useState } from 'react'
import { Link } from 'react-navi'
import styled from '@emotion/styled'
import { get } from 'lodash'

import { CurriculumQuery, CurriculumCoursesQuery } from 'gql'
import { CourseListing, CreateLink, LayoutControls, Results, SearchBar, Sidebar } from 'components'
import { Button, Panel, Subtitle, Title } from 'ui'
import { useDebouncedValue, useParam, useSimpleQuery } from 'utils'

export const CurriculumDetail = () => {
  const [search, setSearch] = useState('')
  const [layout, setLayout] = useState('list')
  const id = useParam('curriculumID')
  const [data, loading] = useSimpleQuery(CurriculumQuery, { id })
  const debouncedSearch = useDebouncedValue(search)

  return loading || (
    <Panel>

      <Panel.Header>
        <Title>{data.node.name}</Title>
        <EditLink variant='persist' href='./manage'>Edit</EditLink>
      </Panel.Header>

      <Panel.Section>

        <CourseContainer>
          <Subtitle>Courses</Subtitle>
          <CreateLink href='../../courses/new'>Create Course</CreateLink>
        </CourseContainer>

        <LayoutOptions>
          <SearchBar placeholder='Course Name' setSearch={setSearch} />
          <LayoutControls layout={layout} setLayout={setLayout} />
        </LayoutOptions>

        <Results query={CurriculumCoursesQuery} id={id} search={debouncedSearch}>
          {nodes => (
            <CourseListing.Container layout={layout}>
              {nodes.map(node => (
                <CourseListing
                  course={node}
                  href={`../../courses/${node.id}`}
                  key={node.id}
                  layout={layout}
                />
              ))}
            </CourseListing.Container>
          )}
        </Results>

      </Panel.Section>
    </Panel>
  )
};

// Components and Styles

const EditLink = Button.withComponent(Link)

const CourseContainer = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
`
const LayoutOptions = styled.div`
  display:flex;
  margin-bottom:40px;
`

// Sidebar

CurriculumDetail.Sidebar = () => {
  const id = useParam('curriculumID')
  const [data, loading, errors] = useSimpleQuery(CurriculumCoursesQuery, { id })
  const total = get(data, 'node.courses.totalCount', 0)
  return errors ? errors[0].message : (
    <Sidebar.Container>
      <Sidebar.Dewey />
      <Sidebar.Message loading={loading}>
        You have {total} courses in this curriculum.
        <br /><br />
        Would you like to create another?
      </Sidebar.Message>
      <Sidebar.Action href='../../courses/new'>Create Course</Sidebar.Action>
    </Sidebar.Container>
  )
}
