import React from 'react'
import styled from '@emotion/styled'
import { DiAndroid } from 'react-icons/di'
import { withProps } from 'recompose'
import { Link } from 'react-navi'

import { COLORS, FONT_SIZES, Icon, Button } from 'ui'

export const Sidebar = {
  Container: styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:88px 0;
  `,
  Message: styled.p`
    font-size:${FONT_SIZES.XXLARGE};
    font-weight:300;
    margin:40px 0;
    width:200px;
    line-height:1.3;
    text-align:center;
    opacity:1;
    transition:opacity 300ms ease;
    ${props => props.loading && 'opacity:0'};
  `,
  Dewey: () => (
    <DeweyContainer>
      <DeweyIcon>
        <DiAndroid />
      </DeweyIcon>
    </DeweyContainer>
  ),
  Action: styled(withProps({ variant: 'action' })(Button.withComponent(Link)))`
    &:hover {
      color:${COLORS.ACTION_TEXT};
    }
  `
}

// Components and Styles

const DeweyContainer = styled.div`
  width:100px;
  height:100px;
  border-radius:100px;
  border:3px solid ${COLORS.ACTION};
  overflow:hidden;
  background-color:${COLORS.BACKGROUND};
`
const DeweyIcon = styled(Icon)`
  font-size:114px;
  margin-left:-10px;
`
