import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { MdModeEdit } from 'react-icons/md'
import { Link } from 'react-navi'
import { truncate } from 'lodash'

import { COLORS } from 'ui'

export const CourseListing = ({ course, layout, href }) => (
  <Container layout={layout}>
    <HeroImage layout={layout} img={course.heroImage} href={href} />
    <TitleHolder layout={layout} href={href}>
      <Title layout={layout} title={course.name}>
        {course.name}
      </Title>
    </TitleHolder>
    <Description layout={layout} title={course.description}>
      {truncate(course.description, { length: 160, separator: ' ' })}
    </Description>
    <DataCanvas layout={layout} />
    <DataLabel layout={layout} style={{ gridArea: 'objlabel' }}>
      Learning Objects
    </DataLabel>
    <DataPoint layout={layout} style={{ gridArea: 'obj' }}>
      {course.learningObjects.totalCount} Learning Objects
    </DataPoint>
    <DataLabel layout={layout} style={{ gridArea: 'seqlabel' }}>
      Sequential
    </DataLabel>
    <DataPoint layout={layout} style={{ gridArea: 'seq' }}>
      {course.isSequential ? 'Yes' : 'No'}
    </DataPoint>
    <EditLink layout={layout} href={href}>
      <MdModeEdit />
    </EditLink>
  </Container>
)

CourseListing.Container = styled.div`
  display:grid;
  grid-column-gap:32px;
  grid-row-gap: ${props => props.layout === 'list' ? '8px' : '32px'};
  grid-template-columns: ${props => props.layout === 'list' ? '1fr' : 'repeat(auto-fill, minmax(210px, 1fr))'};
`

// Components and Styles

const Container = styled.div`
  position:relative;
  display:grid;
  grid-column-gap:8px;
  background-color:${COLORS.BACKGROUND_ACCENT};
  box-shadow:0 2px 4px rgba(0,0,0,0.16);
  ${props => props.layout === 'list' && css`
    width:100%;
    height:60px;
    grid-template-columns: 80px 3fr 2fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
      "image title obj seq edit"
      "image desc obj seq edit";
    grid-column-gap:16px;
  `}
  ${props => props.layout === 'grid' && css`
    background-color:${COLORS.BACKGROUND};
    width:auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr 70px 50px;
    grid-template-areas: 
      "image image"
      "desc desc"
      "objlabel obj"
      "seqlabel seq";
  `}
`
const HeroImage = styled(Link)`
  grid-area:image;
  background-color:${COLORS.PRIMARY_ACCENT};
  background-size:cover;
  font-size:0;
  ${props => props.img && props.img.url && css`
    background-image:url(${props.img.url});
  `}
  ${props => props.layout === 'grid' && css`
    position:relative;
    padding-bottom:66.66%;
    font-size:1px;
    &:after {
      content: '';
      position:absolute;
      display:block;
      left:0;
      bottom:0;
      width:100%;
      height:50%;
      background: linear-gradient(transparent, rgba(0,0,0,0.75));
    }
  `}
`
const TitleHolder = styled(Link)`
  ${props => props.layout === 'list' && css`
    grid-area:title;
    align-self:end;
  `}
  ${props => props.layout === 'grid' && css`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    padding-top:66.66%;
  `}
`
const Title = styled.p`
  line-height:1.2;
  ${props => props.layout === 'list' && css`
    font-size:14px;
    color:${COLORS.NEUTRAL};
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    &:hover {
      color:${COLORS.NEUTRAL};
      text-decoration:underline;
    }
  `}
  ${props => props.layout === 'grid' && css`
    position:absolute;
    bottom:0;
    left:0;
    with:100%;
    padding:16px;
    color:#ffffff;
    font-size:20px;
    text-shadow:0 2px 6px rgba(0,0,0,0.8);
    &:hover {
      color:#ffffff;
      text-decoration:underline;
    }
  `}
`
const Description = styled.p`
  grid-area:desc;
  overflow:hidden;
  ${props => props.layout === 'list' && css`
    align-self:start;
    padding-top:4px;
    font-size:12px;
    color:${COLORS.NEUTRAL};
    white-space:nowrap;
    text-overflow:ellipsis;
  `}
  ${props => props.layout === 'grid' && css`
    align-self:end;
    position:relative;
    font-size:14px;
    color:${COLORS.SECONDARY};
    padding:16px;
    line-height:1.2;
  `}
`
const DataLabel = styled.label`
  font-size:16px;
  line-height:1.2;
  color:${COLORS.NEUTRAL};
  align-self:center;
  font-weight:600;
  padding:16px;
  ${props => props.layout === 'list' && css`
    position:absolute;
    visibility:hidden;
  `}
`
const DataPoint = styled.p`
  font-size:12px;
  color:${COLORS.NEUTRAL};
  line-height:1.2;
  align-self:center;
  ${props => props.layout === 'list' && css`
    text-align:center;    
  `}
  ${props => props.layout === 'grid' && css`
    text-align:left;
    padding-top:4px;
    padding-right:16px;
  `}
`
const EditLink = styled(Link)`
  height:1em;
  ${props => props.layout === 'list' && css`    
    grid-area:edit;
    align-self:center;
    font-size:14px;
    color:${COLORS.NEUTRAL};
    &:hover {
      color:${COLORS.NEUTRAL_ACCENT};
    }
  `}
  ${props => props.layout === 'grid' && css`
    position:absolute;
    color:#ffffff;
    filter: drop-shadow(0 2px 2px rgba(0,0,0,0.32));
    top:12px;
    right:12px;
    font-size:24px;
    &:hover {
      color:#dddddd;
    }
  `}
`
const DataCanvas = styled.div`
  background-color:${COLORS.BACKGROUND_ACCENT};
  ${props => props.layout === 'list' && css`    
    grid-area: 1 / 3 / 3 / 6
  `}
  ${props => props.layout === 'grid' && css`
    grid-area: 3 / 1 / 6 / 3
  `}
`
