import { gql } from 'apollo-boost'

export const RequestProcessedSubscription = gql`
  subscription onRequestProcessed ($requestID: ID!) {
    requestProcessed(requestID: $requestID) {
      requestID
      status
      error
    }
  }
`