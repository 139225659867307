import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import auth from './app-auth';
import { setContext } from 'apollo-link-context';


const httpLink = createUploadLink({ uri: "https://qa.api.biw.cloud/le-graphql-controller/v1/graphql" })

const wsLink = new WebSocketLink({
  uri: `wss://qa.api.biw.cloud/le-graphql-controller/v1/notifications`,
  options: {
    reconnect: true
  }
});

const authLink = setContext((req, { headers }) => {
  const token = auth.state.delegateToken || auth.state.accessToken
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      company_id: auth.state.claims.company_id ? auth.state.claims.company_id : "",
      person_id: auth.state.claims.person_id ? auth.state.claims.person_id : ""
    }
  }
})

const link = authLink.concat(split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink,
))

export default new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    },
    query: {
      fetchPolicy: 'network-only'
    }
  }
})
