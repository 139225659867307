import { gql } from 'apollo-boost'

import { CurriculumInfo } from './fragments'

export const CurriculumsQuery = gql`
  ${CurriculumInfo}
  query Curriculums($first: Int, $after: String, $search: String) {
    viewer {
      curriculums(first: $first, after: $after, search: $search) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...CurriculumInfo
          }
        }
      }
    }
  }
`

CurriculumsQuery.edgePath = 'viewer.curriculums'