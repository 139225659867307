import React from 'react'
import styled from '@emotion/styled'

import { COLORS, FONT_SIZES, Switch } from 'ui'

export const SwitchBox = ({ title, children, checked, onChange }) => (
  <Container>
    <Label>
      {title}
      <StyledSwitch checked={checked} onChange={(e) => onChange && onChange(e.target.checked)} />
    </Label>
    <Description>
      {children}
    </Description>
  </Container>
)

// Components and Styles

const Container = styled.div`
  margin-top:32px;
  max-width:180px;
`
const Label = styled.label`
  display:flex;
  align-items:center;
  height:50px;
  color:${COLORS.TERTIARY_TEXT};
  font-size:${FONT_SIZES.MEDIUM};
  font-weight:400;
`
const StyledSwitch = styled(Switch)`
  margin-left:16px;
`
const Description = styled.div`
  font-size:12px;
  line-height:1.3;
  color:${COLORS.TERTIARY_TEXT};
`
