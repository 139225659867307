import React, { useRef, useState } from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { IoMdMore } from 'react-icons/io'
import { get, differenceBy, findIndex, without } from 'lodash'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Results } from 'components'
import { Grid, Col, COLORS, Icon, PlainButton } from 'ui'
import { useSimpleQuery } from 'utils'


export const EdgeField = ({ name, ...userProps }) => (
  <Field name={name}>
    {formikProps => (
      <FieldHandler {...formikProps} {...userProps} /> 
    )}
  </Field>
)

export const FieldHandler = ({ field, form, ...props }) => {
  const { connectedQuery, connectedArgs, availableQuery, canReorder } = props
  const viewportRef = useRef(null)
  const originalConnectionsRef = useRef(null)
  const [data, loading] = useSimpleQuery(connectedQuery, connectedArgs)
  const [connections, setConnections] = useState(null)

  if (loading) {
    return null
  }

  if (!connections) {
    const connection = get(data, connectedQuery.edgePath)
    const original = connection ? connection.edges.map(edge => edge.node) : []
    originalConnectionsRef.current = original
    setConnections(original)
    return null
  }

  const updateValue = current => {
    const original = originalConnectionsRef.current
    const created = differenceBy(current, original, 'id')
    const deleted = differenceBy(original, current, 'id')
    const updated = current.filter((node, i) => (
      !created.includes(node) && findIndex(original, { id: node.id }) !== i)
    )
    const changes = [
      ...created.map(node => ({
        action: 'CREATE',
        destID: node.id,
        destWeight: current.indexOf(node)
      })),
      ...updated.map(node => ({
        action: 'UPDATE',
        destID: node.id,
        destWeight: current.indexOf(node)
      })),
      ...deleted.map(node => ({
        action: 'DELETE',
        destID: node.id,
        destWeight: null
      }))
    ]
    form.setFieldTouched(field.name, true)
    form.setFieldValue(field.name, changes)
    console.log(changes)
  }

  const connect = node => () => {
    const current = [...connections, node]
    updateValue(current)
    setConnections(current)
  }

  const disconnect = node => () => {
    const current = without(connections, node)
    updateValue(current)
    setConnections(current)
  }

  const reorder = dropResult => {
    const from = dropResult.source.index
    const to = dropResult.destination.index
    const current = [...connections]
    current.splice(to, 0, current.splice(from, 1)[0])
    updateValue(current)
    setConnections(current)
  }

  return (
    <Grid cols={2}>
      <DragDropContext onDragEnd={reorder}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <NodeBucket area={1} ref={provided.innerRef} {...provided.droppableProps}>
              {connections.map((node, i) => (
                <Draggable key={node.id} draggableId={node.id} index={i}>
                  {(provided, snapshot) => (
                    <ConnectedNode
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      dragHandleProps={provided.dragHandleProps}
                      isDraggable={canReorder}
                      style={provided.draggableProps.style}
                      node={node}
                      onDisconnect={disconnect(node)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </NodeBucket>
          )}
        </Droppable>
      </DragDropContext>
      <NodeBucket area={2} ref={viewportRef}>
        <Results query={availableQuery} viewportRef={viewportRef}>
          {nodes => differenceBy(nodes, connections, 'id').map(node => (
            <AvailableNode key={node.id} node={node} onConnect={connect(node)} />
          ))}
        </Results>
      </NodeBucket>
    </Grid>
  )
}


// Subcomponents

const ConnectedNode = React.forwardRef(({ node, onDisconnect, dragHandleProps, ...props }, ref) => (
  <NodeContainer ref={ref} {...props}>
    <DragContainer {...dragHandleProps}>
      <DragIcon><IoMdMore /></DragIcon>
      <DragIcon><IoMdMore /></DragIcon>
    </DragContainer>
    <NodeTitle>{node.name}</NodeTitle>
    <RemoveButton type='button' onClick={onDisconnect}>
      <MdRemoveCircleOutline />
    </RemoveButton>
  </NodeContainer>
))
const AvailableNode = ({ node, onConnect }) => (
  <NodeContainer>
    <AddButton type='button' onClick={onConnect}>
      <MdAddCircleOutline />
    </AddButton>
    <NodeTitle>{node.name}</NodeTitle>
  </NodeContainer>
)


// Base Components and Styles

const NodeBucket = styled(Col)`
  height:400px;
  overflow-y:scroll;
  overflow-x:hidden;
  padding:4px;
`
const NodeContainer = styled.div`
  display:flex;
  height:40px;
  align-items:center;
  padding:0;
  background-color:${COLORS.BACKGROUND_ACCENT};
  margin-bottom:8px;
  box-shadow:0 2px 4px rgba(0,0,0,0.16);
  font-size:14px;
  color:${COLORS.NEUTRAL};
`
const NodeTitle = styled.span`
  flex:1 0;
`
const RemoveButton = styled(PlainButton)`
  position:relative;
  top:-1px;
  display:block;
  color:${COLORS.DESTRUCTIVE};
  font-size:24px;
  height:36px;
  width:40px;
`
const AddButton = styled(PlainButton)`
  position:relative;
  top:-1px;
  display:block;
  color:${COLORS.ACTION};
  font-size:24px;
  height:36px;
  width:40px;
  margin-right:8px;
`
const DragIcon = styled(Icon)`
  font-size:26px;
  line-height:26px;
  color:${COLORS.SECONDARY};
  width:6px;
  overflow:hidden;
  text-indent:-10px;
`
const DragContainer = styled.span`
  display:block;
  margin:-2px 16px 0 8px;
`
