import { useEffect, useRef } from 'react'

/* -------------------------------------------------------------------------- *
 * useInfiniteScroll
 *
 * Hook that executes a callback when the referenced element's bottom edge
 * scrolls within 500px of the viewport bottom. Example usage:
 * 
 * const MyListComponent = () => {
 *   const [results, setResults] = useState([])
 *   
 *   const [ref, block, unblock, check] = useInfiniteScroll(async () => {
 *     // Disable callbacks while request pending; replaces for debounce
 *     block()
 *     const newResults = await fetchMoreResultsSomehow()
 *     setResults([...results, ...newResults])
 *     // Re-enable callbacks
 *     unblock()
 *   })
 *   
 *   // Force a positioning check and (potentially) callback even if user is not
 *   // scrolling; useful for initial load
 *   check()
 * 
 *   return (
 *     // Assign ref to element
 *     <div ref={ref}>
 *       {results.map(result => <Result key={result.id} result={result} />)}
 *     </div>
 *   )
 * }
 * -------------------------------------------------------------------------- */
export const useInfiniteScroll = (cb, viewportRef) => {

  // Element reference
  const ref = useRef(null)

  // If we useState() we'll end up re-rendering on every change. Since the
  // whole point of useEffect is to be an imperative escape hatch, we need an
  // imperative, persistent plain object to store scheduling information.
  const state = useRef({
    cb,
    blocked: false,
    scheduled: false
  })

  // Update callback reference. Alternatively, we could pass cb to useEffect so
  // that it cleans up and re-adds the scrollHandler whenever cb changes -- but
  // we would also need to create a new scrollHandler each time. Since we
  // already have a persistent state object, this is simpler.
  state.current.cb = cb

  // Block further callbacks until unblock() is called
  const block = () => {
    state.current.blocked = true
  }
  // Allow callbacks again
  const unblock = () => {
    state.current.blocked = false
  }
  // Force a dimensions check even if scroll is not triggered
  const check = () => {
    scrollHandler()
  }

  useEffect(
    () => {
      const viewport = viewportRef && viewportRef.current
        ? viewportRef.current
        : window
      viewport.addEventListener('scroll', scrollHandler)
      return () => {
        viewport.removeEventListener('scroll', scrollHandler)
      }
    },
    [viewportRef]
  )

  const scrollHandler = () => {
    window.requestAnimationFrame(() => {
      if (!ref.current) return
      if (state.current.blocked) return
      if (state.current.scheduled) return
      state.current.scheduled = true
      const { bottom } = ref.current.getBoundingClientRect()
      const viewportBottom = viewportRef && viewportRef.current
        ? window.scrollY + viewportRef.current.getBoundingClientRect().bottom
        : window.scrollY + window.innerHeight
      const elBottom = window.scrollY + bottom
      if (state.current.cb && (elBottom - viewportBottom < 500)) {
        state.current.cb()
      }
      state.current.scheduled = false
    })
  }

  return [ref, block, unblock, check, viewportRef]
}
