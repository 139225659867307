import styled from '@emotion/styled'
import { withProps } from 'recompose'

import { COLORS } from '../constants/COLORS'
import { FONT_SIZES } from '../constants/FONT_SIZES'

const SPEED = '150ms'
const EASING = 'ease-out'

// Force type='checkbox'
const withCheckboxType = withProps({ type: 'checkbox' })

export const Switch = withCheckboxType(styled.input`
  position:relative;
  appearance:none;
  display:inline-block;
  width:50px;
  height:20px;
  line-height:20px;
  border-radius:20px;
  cursor:pointer;
  background:${COLORS.TERTIARY};
  transition:background ${SPEED} ${EASING};
  overflow:hidden;
  box-sizing:border-box;
  &:before {
    content:'Yes';
    position:absolute;
    display:block;
    width:50px;
    top:1px;
    left:-29px;
    text-indent:9px;
    font-size:${FONT_SIZES.XSMALL};
    color:${COLORS.PRIMARY_TEXT};
    transition:left ${SPEED} ${EASING};
  }
  &:after {
    content:'No';
    position:absolute;
    display:block;
    top:1px;
    left:1px;
    text-indent:25px;
    font-size:${FONT_SIZES.XSMALL};
    color:${COLORS.NEUTRAL};
    display:block;
    width:18px;
    height:18px;
    background-color:#ffffff;
    border-radius:20px;
    transition:left ${SPEED} ${EASING};
    box-sizing:border-box;
  }
  &:focus {
    outline:none;
  }
  &:checked {
    background-color:${COLORS.PRIMARY_ACCENT};
    &:before {
      left:1px;
    }
    &:after {
      left:31px;
    }
  }
`)
